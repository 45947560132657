
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BeneficiaryServiceService } from '~/app/sponsorship/services/beneficary-service.service';
import { ApiResponse, Beneficiary } from '~/app/sponsorship/models/sponsorship.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sponsorships',
  templateUrl: './sponsorships.component.html',
  styleUrls: ['./sponsorships.component.scss']
})
export class SponsorshipsComponent implements OnInit, OnDestroy {
  beneficiaries: Beneficiary[] = [];
  loading = false;
  page = 1;
  pageSize = 10;
  page_error = false;
  items: Beneficiary[] = [];
  pagination!: ApiResponse<Beneficiary>;
  totalItems = 0;
  urlSubscription !: Subscription;
  

  constructor(private beneficiaryService: BeneficiaryServiceService) { }

  ngOnDestroy(): void {
    this.urlSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    this.loading = true;
    this.beneficiaryService.findAll(this.page, this.pageSize, "benefStatus!=WAITING_FOR_PAYMENT", true).subscribe({
      next: (res: ApiResponse<Beneficiary>) => {
        this.items = res.data;
  
        
        this.totalItems = res.totalItems;
        
        this.loading = false;
        this.page_error = false;
      },
      error: (error) => {
        this.loading = false;
        this.page_error = true;
      }
    });
  }

  paginate(p: any){
    console.log(p);
    
    this.page = p
    this.getList()
  }

  pageChange(page:any){
    this.page=page;
    this.getList();
  }
}
