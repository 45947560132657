import { Component, OnInit } from '@angular/core';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { MembershipServiceService } from '../services/membership-service.service';
import { AdhesionType, Membership } from '../models/membership.model';
import { PaymentService } from '~/app/sponsorship/services/payment-service.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentMethodeService } from '~/app/modules/auth/services/payment-methode.service';
import { RegionModel } from '~/app/mutuels/models/regions.model';
import { RegionsService } from '~/app/mutuels/services/regions.service';
import { RelationService } from '../services/relation.service';
import { RelationModel } from '../models/relation.model';
import { Router } from '@angular/router';
import { SharedDataService } from '../services/shared-data.service';

@Component({
  selector: 'app-membership-account',
  templateUrl: './membership-account.component.html',
  styleUrls: ['./membership-account.component.scss']
})
export class MembershipExAccountComponent implements OnInit {
  membershipId: number | null = null;
  phone: string = '';
  SearchCountryField = SearchCountryField;
  selectedC = CountryISO.Senegal;
  structureId: number | null = null;
  mutuelleName: string = '';
  mutuelleNumber: string = '';
  infoAssure: any = {};
  beneficiaryName: string = '';
  beneficiaryRelation: string = '';
  currentStep: number = 1;
  paymentMethodId: number = 1
  selectedMutuelle: any = null;

  // Card details properties
  cardNumber: string = '';
  cardExpireYear: string = '';
  cardExpireMonth: string = '';
  cvv: string = '';
  cardHolderName: string = '';
  clientFirstname: string = '';
  clientLastname: string = '';
  relations: RelationModel[]=[];
  regions: RegionModel[] = [];
  codeAssure: String | null = null;
  selectedRegion: RegionModel | null = null;
  mutuelles: any[] = [];
  selectedMutuelleId: number | null = null;
  defaultRegionName: string = 'LOUGA';


  newMembership: Membership = {
    //dateAdhesion: new Date().toISOString(),
    adhesionType: AdhesionType.Famille,
    nbrMembre: 0,
    prenom: '',
    nom: '',
    adresse: '',
    sexe: 'MASCULIN',
    telephone: '',
    lieuNaissance: '',
    dateNaissance: '',
    typePiece: '',
    numeroPiece: '',
    denomination: '',
    structureId: 0,
    beneficiaryTypeId: 0,
    email: '',
    nbrAdherent: 0,
    photo: '',
    photoContentType: ''
  };

  telephone!: any;
  phone2 !: any
  totalAmount: number = 0;
  readonly COST_PER_MEMBER: number = 3500;
  filteredMutuelles: { id: number, name: string, basicInfo: string, extendedInfo: string, internalInfo: string }[] = [];

  selectedPaymentMethod: string = '';
  paymentMethods: { name: string, image: string, commissionRate: number, totalAmount: string, paymentMethodId: number , categorie: string , nom:string}[] = [];


  constructor(private membershipService: MembershipServiceService,
    private paymentService: PaymentService,
    private toastr: ToastrService,
    private paymentMethodeService: PaymentMethodeService,
    private regionService: RegionsService,
    private router : Router,
    private sharedDataService: SharedDataService
    
  ) { }

  ngOnInit() {
    //this.onRegionChange();
    this.loadPaymentMethods();
    

  }
  
  loadPaymentMethods() {
    this.paymentMethodeService.findAll(0, 10, '', true).subscribe(
      response => {
        console.log('Response from payment methods endpoint:', response); 
        this.paymentMethods = response.data.map(paymentMethod => ({
          name: paymentMethod.operateur.nom,
          image: paymentMethod.operateur.urlLogo,
          commissionRate: paymentMethod.taux,
          totalAmount: '0000 Cfa',
          paymentMethodId: paymentMethod.id,
          categorie: paymentMethod.categorie,
          nom:paymentMethod.nom
        }));
      },
      error => {
        this.toastr.error('Erreur lors du chargement des méthodes de paiement');
      }
    );
  }
  
  onRegionSelect() {
    if (this.selectedRegion) {
      const regionId = this.selectedRegion.id;
      this.regionService.findMutuellesByRegion(regionId).subscribe(
        response => {
          this.mutuelles = response.data;
          this.selectedMutuelleId = null; // Réinitialiser la sélection
          console.log('Mutuelles loaded:', this.mutuelles);
          console.log('Selected Mutuelle ID after load:', this.selectedMutuelleId);
        },
        error => {
          this.toastr.error('Erreur lors du chargement des mutuelles');
        }
      );
    }
  }
  
  
  onMutuelleChange(mutuelleId: String) {
    this.selectedMutuelle = this.mutuelles.find(mutuelle => mutuelle.id === mutuelleId);
    console.log('Mutuelle ID selected:', mutuelleId); // Pour vérification
    console.log('Selected Mutuelle:', this.selectedMutuelle); // Vérification des données
  }
  

  selectPhone(event: any) {
    if (event && event.e164Number) {
      this.newMembership.telephone = event.e164Number;
    }
  }

  selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
    const selectedMethod = this.paymentMethods.find(m => m.name === method);
    if (selectedMethod) {
      this.paymentMethodId = selectedMethod.paymentMethodId;
    }
  }

  showCardFields(): boolean {
    const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
    return selectedMethod ? selectedMethod.categorie === 'card' : false;
  }

  getSelectedPaymentImage(): string {
    const method = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
    return method ? method.image : '';
  }
  

  // goToNextStep1() {
  //   console.log('Selected Mutuelle ID:', this.selectedMutuelleId);
  //   if (this.selectedMutuelleId !== null) {
  //     this.onMutuelleChange(this.selectedMutuelleId);
  //     this.currentStep++;
  //   } else {
  //     this.toastr.error('Veuillez sélectionner une mutuelle.');
  //   }
  //   //this.currentStep++;
  // }
  goToNextStep2() {
    console.log('Selected code Assure:', this.codeAssure);
    if (this.codeAssure !== null) {
      this.membershipService.getBenefByCode(this.codeAssure).subscribe(
        response => {
          this.infoAssure = response;
          this.currentStep++;
          console.log('SEARCH RESULT:', response);
          // this.toastr.success("Membership submitted successfully")
        },
        error => {
          this.infoAssure = null;
          //console.error('Error during membership submission:', error);
          this.toastr.error("Assuré ou bénéficiaire introuvable")
        }
      );
    } else {
      this.toastr.error('Veuillez sélectionner une mutuelle.');
    }
  }

  goToNextStep3() {
    if (this.currentStep < 3) {
      // const transactionDetails = {
      //   id: this.paymentMethodId, 
      //   membership: {
      //     id: this.membershipId
      //   }, 
      //   paymentMethodId: this.paymentMethodId
      // }
      // this.paymentService.initiateTransaction(transactionDetails).subscribe()
      this.currentStep++;
    }
  }

  goToNextStep4() {
    if (this.currentStep < 4) {
      //this.initiatePay();
      this.currentStep++;
    }
  }

  goToPreviousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  goToStep(step: number) {
    this.currentStep = step;
  }
  // initiatePay(): void {
  //   const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
  // const transactionDetails : any ={
  //     id: this.paymentMethodId,
  //     membership: {
  //       id: this.membershipId
  //     },
  //     paymentMethodId: this.paymentMethodId
  //   };
  //   if (selectedMethod?.categorie === 'card') {
  //     transactionDetails.cardNumber = this.cardNumber;
  //     transactionDetails.cardExpireYear = this.cardExpireYear;
  //     transactionDetails.cardExpireMonth = this.cardExpireMonth;
  //     transactionDetails.cvv = this.cvv;
  //     transactionDetails.cardHolderName = this.cardHolderName;
  //     transactionDetails.clientFirstname = this.clientFirstname;
  //     transactionDetails.clientLastname = this.clientLastname;
  //   }
  //   console.log('Initiating transaction with details:', transactionDetails);
  //   this.paymentService.initiateTransaction(transactionDetails).subscribe(
  //     response => {
  //       console.log('Transaction initiated successfully:', response);
  //       this.toastr.success('Transaction initiated successfully');
  //       if (response.launchUrl) {
  //         this.toastr.success("Payment initié avec succès");
  //         window.location.href = response.launchUrl;
  //       } else {
  //         // this.toastr.success("Payment initié avec succès");
  //         // this.router.navigate(['/scanner']);
  //       }
  //     },
  //     error => {
  //       console.error('Error initiating transaction:', error);
  //       this.toastr.error('Error initiating transaction');
  //     }
  //   );
  // }


  submitForm() {
    const payload = {
      //dateAdhesion: this.newMembership.dateAdhesion,
      code: this.codeAssure
    }
    console.log("donnees", payload);

    this.currentStep++;


    this.membershipService.submitLinkCode(payload).subscribe(
      response => {
        console.log('LINK RESULT:', response);
        this.toastr.success("Votre code assuré vient d'être associé avec votre compte avec succès!")

        this.router.navigate(['/']);
      },
      error => {
        this.currentStep = 1;
        console.error('Error during membership submission:', error);
        this.toastr.error("Ce code existe déjà")
      }
    );
  }
}
