import { Component, OnInit, ViewChild } from '@angular/core';
import { AnnuaireService } from '../../services/annuaire.service';
import { RegionService } from '../../services/regions.service';
import { MapInfoWindow, MapMarker, MapDirectionsRenderer, MapDirectionsService } from '@angular/google-maps';

@Component({
  selector: 'app-pharmacies',
  templateUrl: './pharmacies.component.html',
  styleUrls: ['./pharmacies.component.scss']
})
export class PharmaciesComponent implements OnInit {

	public data:any = {page:'', codeType:'', region:'', departement:''};
	public defaultRegions:any = [];
	public loading:boolean = false;
  public searchValues:any = {
    'page': this.data?.page,
    // "codeType": this.data?.codeType,
    "codeType": '1',
    "region": this.data?.region,
    "departement": this.data?.departement,
    "size": 12
  }

  collection:any = [];
  structures:any = [];
  regions:any = [];
  selectRegion:any = '';
  depts:any = [];
  currentPage = 1;
  currentRegionName:any = ''
  currentRegionCoords:any = ''
  p = 1;

  itemsPerPage = 12;
  totalItems : any = 0; 
  center: google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 };
  // zoom = 7.63;
  zoom = 7.63;
  markerPositions: any[] = [];
  markerInfoContent:any = '';
  point: any = {}//current position
  telStructure:string = ''

  infoContent: string = '';
  directions: any | undefined;
  zoomPosition: any;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow?: MapInfoWindow;
  @ViewChild(MapDirectionsRenderer) directionsRenderer!: MapDirectionsRenderer;
  
  constructor(private annuaireService: AnnuaireService, private regionService: RegionService,private directionsService: MapDirectionsService) {
    this.regionService.getDefaultRegions().pipe().subscribe((data:any) => {
			this.defaultRegions = data;
    });
    
  }

  ngOnInit() {
    this.getAllRegions();
    // this.getPharmacies(this.searchValues);
    // this.getPoints();
    this.getCurrentLocation();
    this.getIPAddress();
  }

  

  gty(pageSlected: any){
    this.searchValues.page = pageSlected;
    this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
        this.loading = false;
        this.structures = res.data
        this.totalItems = res.totalItems
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  getAllRegions(){
    this.annuaireService.getAllRegions().subscribe((res:any) => {
      this.loading = false;
      console.log(res)
      this.regions = res.data
    },
    err => {
      this.loading = false;
      // this.modalService.dismissAll();
    }
  );
  }

  choixRegion (){
    this.loading = true;
    let spl = this.data.region.split('-');
    this.searchValues.region = spl[0];
    this.selectRegion = spl[1]
    this.annuaireService.getDepsByRegion(spl[0])
      .subscribe(res => {
        console.log(res['data'])
        this.depts = res.data;
        this.loading = false;
        const index = this.defaultRegions.findIndex((o:any) => o.id == spl[0]);
        this.currentRegionCoords = index !== -1 ? this.defaultRegions[index] :  this.currentRegionCoords;
      });
  }

  getPharmacies(filter:any){
    this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
        this.loading = false;
        this.totalItems = res.totalItems
        this.structures = res.data
        this.zoom = 12
        this.retrieveMapsData();
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  getPoints(region:any){
    var cardValues = {
      "codeType": '1',
      "region": region,
      "departement": '',
      "size": 150,
      "page": 1,
    }
    this.annuaireService.getStructures(cardValues).subscribe((res:any) => {
        this.loading = false;
            // Image personnalisée pour le marqueur
        const customIcon = {
          url: 'https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730122411386-position_copie.png', // URL de l'image personnalisée
          scaledSize: new google.maps.Size(35, 35), // Taille (ajustez si nécessaire)
          origin: new google.maps.Point(0, 0), // Origine de l'image
          anchor: new google.maps.Point(25, 50) // Position du point d'ancrage (centre bas de l'image)
        };
        for (var i= 0 ; i<res.data.length ; i++){
          if (res.data[i].latitude && res.data[i].longitude){
            this.markerPositions.push( 
              {
                position: {lat: Number(res.data[i].latitude)  , lng: Number(res.data[i].longitude)  }, 
                title: res.data[i].structure,
                phone: res.data[i]?.telephone,
                icon: customIcon
                // label: {
                //   color: 'red',
                //   text: 'Marker label 1' ,
                // },
              }
            ) 
          }
        }
        
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  onSubmit(isValid: any){
		if (isValid){
			console.log('submit', this.data);
      this.searchValues.codeType='1'
      this.searchValues.page = this.p
      this.searchValues.size = 12
      this.searchValues.departement = this.data.departement
      if (this.data.departement == 'aucun'){
        this.searchValues.departement = ''
        this.searchValues.region = this.selectRegion;
      }
      if (this.selectRegion) {
        // this.searchValues.region = this.selectRegion;
        this.currentRegionName = ''
      }
      this.loading = true;
      this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
          this.loading = false;
          this.structures = res.data
          this.totalItems = res.totalItems
          this.searchValues.size = 150
          this.retrieveMapsData()
          },
          err => {
            this.loading = false;
            // this.modalService.dismissAll();
          }
      );
		} else{
			
			console.log('submit not valid');
		}
	}

  retrieveMapsData(){

    this.searchValues.size = 150
    const customIcon = {
      url: 'https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730122411386-position_copie.png', // URL de l'image personnalisée
      scaledSize: new google.maps.Size(35, 35), // Taille (ajustez si nécessaire)
      origin: new google.maps.Point(0, 0), // Origine de l'image
      anchor: new google.maps.Point(25, 50) // Position du point d'ancrage (centre bas de l'image)
    };
    this.markerPositions = []
    // this.annuaireService.getStructures(patient).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any) => {
      this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
        this.searchValues.size = 12
        this.loading = false;
        this.zoom = this.currentRegionCoords.code == 'DK' ? 12 : 9;
        this.center = {
          lat: Number(this.currentRegionCoords.lat),
          lng: Number(this.currentRegionCoords.lng),
        };
        for (var i= 0 ; i<res.data.length ; i++){
          if (res.data[i].lat && res.data[i].lng){
            // console.log(res.data[i].lat, res.data[i].lng)
            this.markerPositions.push( 
              {
                position: {lat: Number(res.data[i].lat)  , lng: Number(res.data[i].lng)  }, 
                title: res.data[i].name,

              icon: customIcon
                // label: {
                //   color: 'red',
                //   text: 'Marker label 1' ,
                // },
              }
            ) 
          }
        }
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  getCurrentLocation() {
    this.loading = true;
  
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        this.loading = false;
        this.point = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log(position)
        // this.geocodeLatLng(this.point);
        // google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 }
        this.center = this.point;
        // this.zoom = 13;
        // this.map.panTo(point);
  
        this.markerInfoContent = "I'm here!";
        
      },
      (error) => {
        this.loading = false;
  
        if (error.PERMISSION_DENIED) {
          alert("Couldn't get your location Permission denied");
        } else if (error.POSITION_UNAVAILABLE) {
          alert(
            "Couldn't get your location Position unavailable"
          );
        } else if (error.TIMEOUT) {
          alert("Couldn't get your location Timed out'");
        } else {
          alert(error.message);
        }
      },
      { enableHighAccuracy: true }
    );
  }

  geocodeLatLng(location: any): Promise<any> {
    let geocoder = new google.maps.Geocoder();
  
    return new Promise((resolve, reject) => {
      geocoder.geocode({ 'location': location }, (results:any, status:any) => {
        // const response = new GeocoderResponse(status, results);
        console.log(status, results);
        // resolve(response);
      });
    });
  }

  public getIPAddressOld() {
    this.annuaireService.getIPAddress().subscribe((res:any) => {
      this.loading = false;
      console.log('good',res);
      this.getInfoVille(res.ip)
    },
    err => {
      this.loading = false;
      // this.modalService.dismissAll();
    }
  );
  }

  public getIPAddress() {
    this.annuaireService.getIPAddress().subscribe((res:any) => {
      this.loading = false;
      this.currentRegionName = res.region

      this.searchValues.region = 798;
      const index = this.defaultRegions.findIndex((o:any) => o.code == res.region_code);
      if (index !== -1){
        this.currentRegionCoords = this.defaultRegions[index];
        this.searchValues.region = this.currentRegionCoords.id;
      } 

      this.getPoints(this.searchValues.region);
      
      this.getPharmacies(this.searchValues);
    },
    err => {
        this.loading = false;
        this.currentRegionName = "Dakar"
        const index = this.defaultRegions.findIndex((o:any) => o.code == 'DK');
        if (index !== -1){
          this.currentRegionCoords = this.defaultRegions[index];
          this.searchValues.region = this.currentRegionCoords.id;
        } 
        this.searchValues.region = 798;
        this.getPharmacies(this.searchValues);
    }
  );
  }

  public getInfoVille(ip:any) {
      this.annuaireService.getInfoVille(ip).subscribe((res:any) => {
        this.loading = false;
        this.currentRegionName = res.regionName
        this.getPoints(res.regionName);
        this.searchValues.region = res.regionName
        
        this.getPharmacies(this.searchValues);
      },
      err => {
        this.getPharmacies(this.searchValues);
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  openMapInfo(content: string, phone:string,pos:any, marker: MapMarker): void {
    this.infoContent = content;
    this.infoWindow?.open(marker);
    this.telStructure = phone;
    this.zoomPosition = pos
  }

  zoomer(pos:any){
    // const originPos = { lat: 14.7193856, lng: -17.4522368 };
    const originPos = this.point? this.point : { lat: 14.7193856, lng: -17.4522368 };
    this.center = pos;
    // this.zoom = 7;
    const request: google.maps.DirectionsRequest = {
      origin: originPos,
      destination: pos,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    this.directionsService.route(request).subscribe({
      next: (response) => {
        this.directions = response.result;
      },
      error: (error) => {
        console.error('Error calculating directions:', error);
      },
    });
  }

  voirStructure(item:any){
    if (item.latitude && item.longitude) {
      this.zoom = 17
      // google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 }
      this.center = { lat: Number(item.latitude), lng: Number(item.longitude) };
    }
  }
}
