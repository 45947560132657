import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { PaymentService } from '../services/payment-service.service';
import { ToastrService } from 'ngx-toastr';
import { SponsorshipService } from '../services/sponsorship.service';
import { PaymentMethodeService } from '~/app/modules/auth/services/payment-methode.service';
import { SharedDataService } from '~/app/membership/services/shared-data.service';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss']
})
export class PaymentDetailComponent implements OnInit {
  sponsorshipData: any;
  transactionData: any;

  sponsorshipId: number | null = null;
  selectedType: string = '';
  selectedParrainage: string = '';
  contactPerson: string = '';
  amount: number = 0;
  selectedMutuelle: string = '';
  selectedPaymentMethod: string = '';
  phone: string = '';
  nbBeneficiaries: number = 0;
  paymentMethodId: number = 1; // Valeur par défaut
  

  // Card details properties
  cardNumber: string = '';
  cardExpireYear: string = '';
  cardExpireMonth: string = '';
  cvv: string = '';
  cardHolderName: string = '';
  clientFirstname: string = '';
  clientLastname: string = '';

  paymentMethods: { name: string, image: string, commissionRate: number, totalAmount: string, paymentMethodId: number ,categorie: string , nom :string }[] = [];

  constructor(
    private dataService: DataService,
    private paymentService: PaymentService,
    private router: Router,
    private toastr: ToastrService,
    private sponshorService: SponsorshipService,
    private paymentMethodeService: PaymentMethodeService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit(): void {
  

    this.sponsorshipData = this.dataService.getSponsorshipData();
    console.log('Received sponsorship data:', this.sponsorshipData); // Debugging

    const sponsorshipData = this.dataService.getSponsorshipData();
    this.selectedType = sponsorshipData.type || '';
    this.selectedParrainage = sponsorshipData.parrainage || '';
    this.contactPerson = sponsorshipData.hasContactPerson 
      ? `${sponsorshipData.contactFirstName} ${sponsorshipData.contactLastName}` 
      : 'N/A';
    const paymentData = this.dataService.getPaymentData();
    this.amount = paymentData.total || 0;
    const mutualData = this.dataService.getMutualData();
    this.selectedMutuelle = mutualData.selectedMutuelle || '';
   
    this.sponsorshipId = this.sponshorService.getSponsorshipId();
    this.sponshorService.getAllSponsorships().subscribe(sponsorships => {
      const currentSponsorship = sponsorships.find(s => s.id === this.sponsorshipId);
      if (currentSponsorship) {
        this.nbBeneficiaries = currentSponsorship.nbBeneficiaries || 0;
      }
    });

    this.loadPaymentMethods();
  }

  

  loadPaymentMethods() {
    this.paymentMethodeService.findAll(0, 10, '', true).subscribe(
      response => {
        console.log('Response from payment methods endpoint:', response); 
        this.paymentMethods = response.data.map(paymentMethod => ({
          name: paymentMethod.operateur.nom,
          image: paymentMethod.operateur.urlLogo,
          commissionRate: paymentMethod.taux,
          totalAmount: '0000 Cfa',
          paymentMethodId: paymentMethod.id,
          categorie: paymentMethod.categorie,
          nom: paymentMethod.nom
        }));
      },
      error => {
        this.toastr.error('Erreur lors du chargement des méthodes de paiement');
      }
    );
  }

  selectPaymentMethod(method: string): void {
    this.selectedPaymentMethod = method;
    const selectedMethod = this.paymentMethods.find(m => m.name === method);
    if (selectedMethod) {
      this.paymentMethodId = selectedMethod.paymentMethodId;
    }
  }

  getSelectedPaymentImage(): string {
    const method = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
    return method ? method.image : '';
  }

  showCardFields(): boolean {
    const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
    return selectedMethod ? selectedMethod.categorie === 'card' : false;
  }

  validatePayment(): void {
    const sponsorshipData = this.dataService.getSponsorshipData();
    const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);
  
    const transactionDetails: any = {
      id: this.paymentMethodId,
      recipientPhoneNumber: this.phone,
      sponsorship: {
        id: this.sponsorshipId
      },
      paymentMethodId: this.paymentMethodId
      
    };
    
  
    if (selectedMethod?.categorie === 'card') {
      transactionDetails.cardNumber = this.cardNumber;
      transactionDetails.cardExpireYear = this.cardExpireYear;
      transactionDetails.cardExpireMonth = this.cardExpireMonth;
      transactionDetails.cvv = this.cvv;
      transactionDetails.cardHolderName = this.cardHolderName;
      transactionDetails.clientFirstname = this.clientFirstname;
      transactionDetails.clientLastname = this.clientLastname;
    }
  
    this.paymentService.initiateTransaction(transactionDetails).subscribe(
      transactionResponse => {
        const transactionData = {
          id: transactionResponse.id,
          createdAt: new Date().toISOString(),
          status: true,
          amount: this.amount,
          fee: transactionResponse.fee,
          amountTotal: transactionResponse.amountTotal,
          recipientPhoneNumber: this.phone,
          transactionStatus: transactionResponse.status,
          paymentMethodId: transactionDetails.paymentMethodId,
          paymentMethod: {
            id: transactionDetails.paymentMethodId,
            extendedInfo: 'string',
            internalInfo: 'string'
          },
          transactionType: selectedMethod?.categorie === 'card' ? 'CARD' : 'WAVE',
          extTransactionId: transactionResponse.extTransactionId
        };
        
  
        this.dataService.setTransactionData(transactionData);
        if (selectedMethod?.name === 'Orange Money') {
          if (transactionResponse.qrCode) {
            // alert("om")
            this.sharedDataService.setQrCode(transactionResponse.qrCode);
            this.sharedDataService.setReference(transactionResponse.extTransactionId);
            this.sharedDataService.setAmount(sponsorshipData?.total);
            this.router.navigate(['/scanner']);
          } else {
            this.toastr.error('QR Code manquant pour Orange Money');
          }
        } else if (transactionResponse.launchUrl) {
          window.location.href = transactionResponse.launchUrl;
        } else {
         // this.toastr.error('URL de lancement manquante');
        }
      },
      error => {
        this.toastr.error("Erreur lors de l'initiation de la transaction. Veuillez réessayer.");
      }
    );
  }
  
  

  // validatePayment(): void {
  //   // if (!this.phone) {
  //   //   this.toastr.error('Veuillez remplir tous les champs');
  //   //   return;
  //   // }

  //   const sponsorshipData = this.dataService.getSponsorshipData();
  //   const selectedMethod = this.paymentMethods.find(m => m.name === this.selectedPaymentMethod);

  //   const transactionDetails: any = {
  //     id: this.paymentMethodId,
  //     sponsorship: {
  //       id: this.sponsorshipId
  //     },
  //     paymentMethodId: this.paymentMethodId
  //   };

  //   if (selectedMethod?.categorie === 'card') {
  //     transactionDetails.cardNumber = this.cardNumber;
  //     transactionDetails.cardExpireYear = this.cardExpireYear;
  //     transactionDetails.cardExpireMonth = this.cardExpireMonth;
  //     transactionDetails.cvv = this.cvv;
  //     transactionDetails.cardHolderName = this.cardHolderName;
  //     transactionDetails.clientFirstname = this.clientFirstname;
  //     transactionDetails.clientLastname = this.clientLastname;
  //   }

  //   this.paymentService.initiateTransaction(transactionDetails).subscribe(
  //     transactionResponse => {
  //       const transactionData = {
  //         id: transactionResponse.id,
  //         createdAt: new Date().toISOString(),
  //         status: true,
  //         amount: this.amount,
  //         fee: transactionResponse.fee,
  //         amountTotal: transactionResponse.amountTotal,
  //         recipientPhoneNumber: this.phone,
  //         transactionStatus: transactionResponse.status,
  //         paymentMethodId: transactionDetails.paymentMethodId,
  //         paymentMethod: {
  //           id: transactionDetails.paymentMethodId,
  //           extendedInfo: 'string',
  //           internalInfo: 'string'
  //         },
  //         transactionType: selectedMethod?.categorie === 'card' ? 'CARD' : 'WAVE',
  //         extTransactionId: transactionResponse.extTransactionId
  //       };

  //       this.dataService.setTransactionData(transactionData);
  //       // if (transactionResponse.launchUrl) {
  //       //   this.toastr.success("Payment initié avec succès");
  //       //   window.location.href = transactionResponse.launchUrl;
  //       // } else {
  //       //   this.toastr.success("Payment initié avec succès");
  //       //   this.router.navigate(['/scanner']);
  //       // }
  //       if (selectedMethod?.nom === 'Orange Money') {
  //         if (transactionResponse.qrCode) {
  //           this.router.navigate(['/scanner'], { state: { qrCode: transactionResponse.qrCode } });
  //         } else {
  //           this.toastr.error('QR Code manquant pour Orange Money');
  //         }
  //       } else if (transactionResponse.launchUrl) {
  //         window.location.href = transactionResponse.launchUrl;
  //       } else {
  //         this.toastr.error('URL de lancement manquante');
  //       }
  //     },
  //     error => {
  //       this.toastr.error("Erreur lors de l'initiation de la transaction. Veuillez réessayer.");
  //     }
  //   );
  // }

  cancelPayment(): void {
    this.router.navigate(['/previous-route']);
  }
}
