<app-navbar/>
<div class="container-fluid" id="block_annuaire">
    <!-- <div class="maps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1978576.593390445!2d-14.364391938734007!3d14.403662404350019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2ssn!4v1728297238656!5m2!1sfr!2ssn" width="100%" height="650" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div> -->
    <div class="maps">
        <google-map [center]="center" [zoom]="zoom" width="100%" height="650px">
            <map-marker *ngFor="let position of markerPositions"  #mapMarker="mapMarker" (mapClick)="openMapInfo(position.title!,position.phone, position.position, mapMarker)" [clickable]="true" [title]="position.title" [label]="position.label" [icon]="position.icon" [position]="position.position"></map-marker>
            <map-info-window>
                
                <div style="text-align: center;">
                    <img src="https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730122411386-position_copie.png" alt="Description" style="width: 30px; height: auto; float: left;margin-bottom:15px;">
                    <h5>{{ infoContent }}</h5>
                    <p *ngIf="telStructure"><label>Téléphone:</label> {{telStructure}}</p>
                    
                    <button title="Voir itinéraire" (click)="zoomer(zoomPosition)" style="border: none;margin-bottom: 10px;background: transparent;">
                        <span style="font-size: 11px;">Voir l'itinéraire</span>
                        &nbsp;<img height="30" src="https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730204697109-iteneraire-logo.png"/>
                    </button>
                </div>
            </map-info-window>
           
            <map-directions-renderer *ngIf="directions" [directions]="directions" ></map-directions-renderer>
        </google-map>
    </div>
    <div class="searchblock">
        <form #searchForm="ngForm" (ngSubmit)="onSubmit(searchForm.valid)">
            <div class="row">
                <div class="col-md-3 blone">
                    
                    <input type="text" name="nom" id="nom" #nom="ngModel" [(ngModel)]="data.nom" placeholder="Nom">
                </div>
                <div class="{{!pageId ? 'col-md-3' :  'col-md-3'}} blone">
                    <select name="regions" (change)="choixRegion()" id="regions" #region="ngModel" [(ngModel)]="data.region">
                        <option value="">Région</option>
                        <option *ngFor="let reg of regions;" value="{{reg.id}}-{{reg.name}}">{{reg.name}}</option>
                        
                    </select>
                </div>
                <div class="{{!pageId ? 'col-md-3' :  'col-md-3'}} blone">
                    <select name="departement" id="departement" #departement="ngModel" [(ngModel)]="data.departement">
                        <option value="">Département</option>
                        <option *ngFor="let dept of depts;" value="{{dept.id}}">{{dept.name}}</option>
                    </select>
                </div>
                <!-- <div class="col-md-3 blone d-flex justify-content-center button" style="padding-left: 20px;"> -->
                <div class="{{!pageId ? 'col-md-3' :  'col-md-3'}} blone btn d-flex justify-content-center button">
                    <button type="submit" id="send" class="btn ">Rechercher</button>
                </div>
            </div>
        </form>
    </div>
</div>
<div>
    <div class="trouve">
        <!-- <h1 *ngIf="!totalItems" >Voici le resultat de votre recherche <span *ngIf="currentRegionName">sur {{currentRegionName.toUpperCase()}}</span></h1> -->
        <h1 *ngIf="totalItems" >{{totalItems}} {{pageId =='bureaux_cmu' ? 'Bureaux CMU': 'Points de prestation'}}  {{totalItems > 1 ? 'trouvés' : 'trouvé'}} <span *ngIf="currentRegionName">sur {{currentRegionName}}</span></h1>
        <h1 *ngIf="!totalItems" >{{pageId =='bureaux_cmu' ? 'Aucun bureau CMU trouvé': 'Aucun point de prestation trouvé'}} <span *ngIf="currentRegionName">sur {{currentRegionName}}</span> </h1>
        <div class="result">
            <div class="block"> 
                <div class="row">
                    <div class="col-md-3" *ngFor="let item of structures | paginate: { itemsPerPage: 12, currentPage: p, totalItems:totalItems  }">
                        <a [routerLink]="['/structures/details', item.id]" href="javscript:void(0);" (click)="voirStructure(item)">
                            <div class="item">
                                <div class="logo"><img src="../../../../assets/images/position.png" /></div>
                                <div class="specialiste"><strong>{{item.name}}</strong></div>
                                <div class="specialite">{{item.address}}</div>
                                <div class="tel"><img src="../../../../assets/images/tel.svg" /><span><strong></strong>{{item.telephone}}</span></div>
                            </div>
                        </a>
                    </div>

                    <pagination-controls *ngIf="totalItems" (pageChange)="gty((p = $event))"></pagination-controls>
                    <!-- <pagination-controls *ngIf="structures.count > 0" (pageChange)="gty((p = $event))"></pagination-controls> -->
                   
                </div>
                <div class="row " *ngIf="totalItems">
                    <div class="col-md-12">
                        <div class="pagination">
                            <!-- <div class="list">
                                    <ul>
                                        <li *ngFor="let item of structures | paginate: { itemsPerPage: 12, currentPage: p, totalItems:structures.count  }">{{ item }}</li>
                                    </ul>
                            
                                    <pagination-controls (pageChange)="gty((p = $event))"></pagination-controls>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer/>