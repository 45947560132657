// src/app/services/payment-service.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  // processPayment(paymentDetails: any, provider: string, paymentMethodId: number): Observable<any> {
  //   let apiUrl: string;
  //   switch (provider) {
  //     case 'Wave':
  //       apiUrl = 'https://api.wave.com/payment';
  //       break;
  //     case 'Orange Money':
  //       apiUrl = 'https://api.orange-money.com/payment';
  //       break;
  //     case 'Free Money':
  //       apiUrl = 'https://api.free-money.com/payment';
  //       break;
  //     default:
  //       throw new Error('Unknown payment provider');
  //   }

  //   return this.http.post<any>(apiUrl, { ...paymentDetails, paymentMethodId });
  // }

  initiateTransaction(transactionPayload: any): Observable<any> {
    
    return this.http.post<any>(`${environment.apiBenef}${Endpoint.PAID}`, transactionPayload)
  }

  searchByRef(ref: any): Observable<any> {
    return this.http.post<any>(`${environment.csuMerchGateway}${Endpoint.OM_POST_SEARCH_BY_ID}`, ref);
  }
}
