import { Component } from '@angular/core';
import { Navigation, Router  } from '@angular/router';
import { SharedDataService } from '~/app/membership/services/shared-data.service';
import { PaymentService } from '~/app/sponsorship/services/payment-service.service';

@Component({
  selector: 'app-scanner-details',
  templateUrl: './scanner-details.component.html',
  styleUrls: ['./scanner-details.component.scss']
})
export class ScannerDetailsComponent {
  qrCode: string | null = null;
  referenceTrx: string | null = null;
  amount: string | null = null;

  constructor(private router: Router,
    private sharedDataService: SharedDataService,
    private paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.qrCode = this.sharedDataService.getQrCode();
    this.referenceTrx = this.sharedDataService.getReference();
    // this.referenceTrx = "563f3d95-bcc4-4d60-aeae-bb990cad6f6c";
    this.amount = this.sharedDataService.getAmount();
    setTimeout(this.handleOmCallback, 36000, this.referenceTrx, this.paymentService, this);
    console.log('QR Code received:', this.qrCode);  // Debugging: check if qrCode is received
    console.log('ref trx:', this.referenceTrx);  // Debugging: check if qrCode is received
    console.log('amount trx:', this.amount); 
  }
  // ref:any, payService:PaymentService, context:any
  handleOmCallback(ref:any, payService:PaymentService, context:any){
    payService.searchByRef({reference:ref}).subscribe(
      transactionResponse => {
        if (transactionResponse.status == "PENDING"){
          alert("Le qrCode a expiré");

          context.router.navigate(['/payment-error'], { queryParams: { page: 'parrainage', mtn : context.amount } });
        }else if (transactionResponse.status == "SUCCESS"){
          context.router.navigate(['/payment-success'], { queryParams: { page: 'parrainage', mtn : context.amount } });
        } 
      },
      error => {
        alert("Erreur lors de la recuperation du callback. Veuillez réessayer.");
      }
    );
  }
}
