<div class="payment-success-container">
    <div class="success-icon d-flex justify-content-center">
        <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path fill="#009640"
                d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM227.3 387.3l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L216 308.1l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.2 16.4 6.2 22.6 0z" />
        </svg>
    </div>
    <h1>Paiement Réussi</h1>
    <p>Votre paiement a été traité avec succès.</p>
    <div class="transaction-details">
        <p><strong>Montant payé:</strong> {{mtn}} FCFA</p>
        <!-- <p><strong>ID de transaction:</strong> {{ transactionData?.id }}</p>
        <p><strong>Date:</strong> {{ transactionData?.createdAt | date:'dd/MM/yyyy HH:mm' }}</p> -->
    </div>
    <button (click)="goToHome()">Retour à l'accueil</button>
</div>