import { BaseEntity } from "~/commons/entities";

export interface Sponsorship {
    id?: number
    status: boolean;
    nbBeneficiaries: number;
    membershipFeesIncluded?: boolean;
    hasContactPerson: boolean;
    contactFirstName?: string;
    contactLastName?: string;
    contactPhone?: string;
    sponsorshipStatus?: string;
    membershipFeesTotal?: number;
    subscriptionTotal?: number;
    categoryTBCode?: string;
    total?: number;
    donor? : Donor;
    mutualId: number;
    mutual?: Mutual;
    beneficiaryTypeId: number;
    beneficiaryType?: BeneficiaryType;
    membershipTypeId?: number;
    membershipType?: MembershipTypeEnum;
    family?: Family;
    beneficiaries?: Beneficiary[];
    membershipTypeEnum?: MembershipTypeEnum;
  }
  
  export interface Donor {
    status: boolean;
    userJson: string;
  }
  
  export interface Mutual {
    basicInfo: BasicInfo;
    extendedInfo: string;
    internalInfo: string;
   
  }
  export interface BasicInfo {
    libelle?: string; 
  }
  
  export interface BeneficiaryType {
    basicInfo: string;
    extendedInfo: string;
    internalInfo: string;
  }
  
  export interface MembershipType {
    basicInfo: string;
    extendedInfo: string;
    internalInfo: string;
  }
  
  export interface Family {
    id ? : number,
    status?: boolean;
    name?: string;
    members?: number;
    beneficiaries ?: Beneficiary[];
  }
  
  export interface Beneficiary  extends BaseEntity {
    status: boolean;
    firstName: string;
    lastName: string;
    phone: string;
    benefStatus: string;
    extCode: string;
    code: string;
    houseHolder: boolean;
    qrCodeUrl: string;
    mutualId: number;
    createdAt?: string;
    mutual?: Mutual;
    membershipType?: MembershipTypeEnum;
  }
  

  export enum MembershipTypeEnum {
    FAMILLE = 'FAMILLE',
    GROUPE = 'GROUPE'
}

export class ApiResponse<T>{
  data: T[] = [];
  totalItems!: number;
  totalPages!: number;
}
 