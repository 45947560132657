export enum AdhesionType {
    Famille = 'FAMILLE',
    Individuel = 'INDIVIDUEL'
  }
  
  export interface Membership {
    id ? : number,
    dateAdhesion ?: string;
    adhesionType: AdhesionType;
    nbrMembre: number;
    nbrAdherent?: number;
    prenom: string;
    nom: string;
    adresse: string;
    sexe: 'MASCULIN' | 'FEMININ';
    email ?: string;
    telephone: string;
    lieuNaissance: string;
    dateNaissance: string;
    typePiece: string;
    numeroPiece: string;
    denomination: string;
    structureId: number;
    beneficiaryTypeId: number;
    photo?: string;
    photoContentType?: string;
    mutual?: {
      id: number;
      basicInfo: {
        libelle: string;
        region?: { libelle: string };
        departement?: { libelle: string };
      };
    };
  }

  export interface MembershipResponse {
    data: Membership[];
    message: string;
    status: number;
  }

  export interface LinkCode {
    code ?: string;
  }

  export interface LinkCodeResponse {
    message: string;
    status: number;
  }
