import { Component, OnInit, ViewChild } from '@angular/core';
import { AnnuaireService } from '../../services/annuaire.service';
import { ActivatedRoute } from '@angular/router';
import { MapInfoWindow, MapMarker, MapDirectionsRenderer, MapDirectionsService } from '@angular/google-maps';


@Component({
  selector: 'app-structures',
  templateUrl: './structures.component.html',
  styleUrls: ['./structures.component.scss']
})
export class StructuresComponent implements OnInit {

	public data:any = {page:'', codeType:'PS', region:'', departement:''};
	public loading:boolean = false;
  public searchValues:any = {
    'page': this.data?.page,
    "codeType": this.data?.codeType,
    // "codeType": 'PS',
    "region": this.data?.region,
    "departement": this.data?.departement,
    "size": 12,
  }

  collection:any = [];
  structures:any = [];
  regions:any = [];
  selectRegion:any = '';
  depts:any = [];
  currentPage = 1;
  currentRegionName:any = ''
  p = 1;
  pageId:any = ''
  itemsPerPage = 12;
  totalItems : any = 0; 
  center: google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 };
  zoom = 7.63;
  telStructure:string = ''
  infoContent: string = '';
  zoomPosition: any;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow?: MapInfoWindow;
  @ViewChild(MapDirectionsRenderer) directionsRenderer!: MapDirectionsRenderer;

  markerPositions: any[] = [];

  
  directions: any | undefined;
  types: any[] = [{'code':'INITIATIVE_GRATUITE_CSU', 'nom':'INITIATIVE GRATUITE CSU'}, 
    {'code':'SRMSD', 'nom':'SERVICE  REGIONAL'},{'code':'PS', 'nom':'POSTE DE SANTE'},{'code':'CENTRE_SANTE', 'nom':'CENTRE DE SANTE'}, 
    {'code':'EPS', 'nom':'Etablissement de Santé Publique'},{'code':'CASE_SANTE', 'nom':'Case de santé'} 
  ];
  markerInfoContent:any = '';
  point: any = {}//current position

  currentTypeStructure:any = this.pageId =='bureaux_cmu' ? {'code':'INITIATIVE_GRATUITE_CSU', 'nom':'INITIATIVE GRATUITE CSU'} : {'code':'PS', 'nom':'POSTE DE SANTE'};
  
  constructor(private annuaireService: AnnuaireService, private route: ActivatedRoute, private directionsService: MapDirectionsService) {
  }

  ngOnInit() {
    this.pageId = this.route.snapshot.paramMap.get('id');
    this.getAllRegions();
    // this.getPharmacies(this.searchValues);
    // this.getPoints();
    this.getCurrentLocation();
    this.getIPAddress();
  }

  // markerPositions: any[] = [
  //   { position: {lat: 14.866106, lng: -16.804148 }, 
  //   title: 'Marker label 1' ,
  //     // label: {
  //     //   color: 'red',
  //     //   text: 'Marker label 1' ,
  //     // },
  //   },
  //   { position: {lat: 14.791763, lng: -17.304026}, 
  //     title: 'Marker label 2' ,
  //     // label: {
  //     //   color: 'red',
  //     //   text: 'Marker label 2' ,
  //     // }, 
  //   }
  // ];

  

  gty(pageSlected: any){
    this.searchValues.page = pageSlected;
    this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
        this.loading = false;
        this.structures = res.data
        this.totalItems = res.totalItems
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  getAllRegions(){
    this.annuaireService.getAllRegions().subscribe((res:any) => {
      this.loading = false;
      console.log(res)
      this.regions = res.data
    },
    err => {
      this.loading = false;
      // this.modalService.dismissAll();
    }
  );
  }

  choixRegion (){
    this.loading = true;
    let spl = this.data.region.split('-');
    this.selectRegion = spl[1]
    this.annuaireService.getDepsByRegion(spl[0])
      .subscribe(res => {
        // console.log(res['hydra:member'])
        this.depts = res.data;
        this.loading = false;
      });
  }

  choixStructure(){
    const index = this.types.findIndex(o => o.code == this.data.codeType);
    this.currentTypeStructure = this.types[index];
    // console.log('typeS', this.currentTypeStructure)
  }

  getPharmacies(filter:any){
    this.searchValues.codeType =  this.pageId =='bureaux_cmu' ? 'INITIATIVE_GRATUITE_CSU' : this.searchValues.codeType; 
    this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
        this.loading = false;
        this.totalItems = res.totalItems
        this.structures = res.data
        
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  getPoints(region:any){
    var cardValues = {
      "region": region,
      "codeType": '',
      "departement": '',
      "size": 50,
      "page": 1,
    }

    this.data.codeType =  this.pageId =='bureaux_cmu' ? 'INITIATIVE_GRATUITE_CSU' : this.data.codeType;
    if (this.data.codeType) {
      cardValues.codeType = this.data.codeType;
    }
    const customIcon = {
      url: 'https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730122411386-position_copie.png', // URL de l'image personnalisée
      scaledSize: new google.maps.Size(35, 35), // Taille (ajustez si nécessaire)
      origin: new google.maps.Point(0, 0), // Origine de l'image
      anchor: new google.maps.Point(25, 50) // Position du point d'ancrage (centre bas de l'image)
    };
    this.annuaireService.getStructures(cardValues).subscribe((res:any) => {
        this.loading = false;
        for (var i= 0 ; i<res.data.length ; i++){
          if (res.data[i].latitude && res.data[i].longitude){
            this.markerPositions.push( 
              {
                position: {lat: Number(res.data[i].latitude)  , lng: Number(res.data[i].longitude)  }, 
                title: res.data[i].structure,
                icon: customIcon,
                // label: {
                //   color: 'red',
                //   text: 'Marker label 1' ,
                // },
              }
            ) 
          }
        }
        
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  onSubmit(isValid: any){
		if (isValid){
			// console.log('submit', this.data);
      this.searchValues.codeType=this. data.codeType;
      this.searchValues.page = this.p
      this.searchValues.size = 12
      this.searchValues.departement = this.data.departement
      if (this.data.departement == 'aucun'){
        this.searchValues.departement = ''
        this.searchValues.region = this.selectRegion;
      }
      if (this.selectRegion) {
        this.searchValues.region = this.selectRegion;
        this.currentRegionName = ''
      }
      this.loading = true;
      const customIcon = {
        url: 'https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730122411386-position_copie.png', // URL de l'image personnalisée
        scaledSize: new google.maps.Size(35, 35), // Taille (ajustez si nécessaire)
        origin: new google.maps.Point(0, 0), // Origine de l'image
        anchor: new google.maps.Point(25, 50) // Position du point d'ancrage (centre bas de l'image)
      };
      // this.annuaireService.getStructures(patient).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any) => {
      this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {

        this.zoom = 7;
          this.loading = false;
          this.structures = res.data
          this.totalItems = res.totalItems
          for (var i= 0 ; i<res.data.length ; i++){
            if (res.data[i].latitude && res.data[i].longitude){
              this.markerPositions.push( 
                {
                  position: {lat: Number(res.data[i].latitude)  , lng: Number(res.data[i].longitude)  }, 
                  title: res.data[i].structure, 
                  icon: customIcon 
                  // label: {
                  //   color: 'red',
                  //   text: 'Marker label 1' ,
                  // },
                }
              ) 
            }
          }
        },
        err => {
          this.loading = false;
			    // this.modalService.dismissAll();
        }
      );
		} else{
			
			console.log('submit not valid');
		}
	}

  getCurrentLocation() {
    this.loading = true;
  
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        this.loading = false;
        this.point = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log('position actuelle', position)
        // this.geocodeLatLng(this.point);
        // google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 }
        this.center = this.point;
        // this.map.panTo(point);
  
        this.markerInfoContent = "I'm here!";
        
      },
      (error) => {
        this.loading = false;
  
        if (error.PERMISSION_DENIED) {
          alert("Couldn't get your location Permission denied");
        } else if (error.POSITION_UNAVAILABLE) {
          alert(
            "Couldn't get your location Position unavailable"
          );
        } else if (error.TIMEOUT) {
          alert("Couldn't get your location Timed out'");
        } else {
          alert(error.message);
        }
      },
      { enableHighAccuracy: true }
    );
  }

  geocodeLatLng(location: any): Promise<any> {
    let geocoder = new google.maps.Geocoder();
  
    return new Promise((resolve, reject) => {
      geocoder.geocode({ 'location': location }, (results:any, status:any) => {
        // const response = new GeocoderResponse(status, results);
        console.log(status, results);
        // resolve(response);
      });
    });
  }

  public getIPAddress() {
    this.annuaireService.getIPAddress().subscribe((res:any) => {
      this.loading = false;
      this.currentRegionName = res.region
      this.getPoints(res.regionName);
      this.searchValues.region = res.regionName
      
      this.getPharmacies(this.searchValues);
    },
    err => {
      this.loading = false;
      // this.modalService.dismissAll();
    }
  );
  }

  public getInfoVille(ip:any){
      this.annuaireService.getInfoVille(ip).subscribe((res:any) => {
        this.loading = false;
        this.currentRegionName = res.regionName
        this.getPoints(res.regionName);
        this.searchValues.region = res.regionName
        
        this.getPharmacies(this.searchValues);
      },
      err => {
        this.getPharmacies(this.searchValues);
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  openMapInfo(content: string, phone:string,pos:any, marker: MapMarker): void {
    this.infoContent = content;
    this.infoWindow?.open(marker);
    this.telStructure = phone;
    this.zoomPosition = pos
  }
  zoomer(pos:any){
    // const originPos = { lat: 14.7193856, lng: -17.4522368 };
    const originPos = this.point;
    console.log('pointId',this.point)
    this.center = pos;
    // this.zoom = 7;
    const request: google.maps.DirectionsRequest = {
      origin: originPos,
      destination: pos,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    this.directionsService.route(request).subscribe({
      next: (response) => {
        this.directions = response.result;
      },
      error: (error) => {
        console.error('Error calculating directions:', error);
      },
    });
  }

  voirStructure(item:any){
    if (item.latitude && item.longitude) {
      this.zoom = 17
      // google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 }
      this.center = { lat: Number(item.latitude), lng: Number(item.longitude) };
    }
  }
}
