import { Component, OnInit, ViewChild } from '@angular/core';
import { AnnuaireService } from '../../services/annuaire.service';
import { ActivatedRoute } from '@angular/router';
import { RegionService } from '../../services/regions.service';
import { MapInfoWindow, MapMarker, MapDirectionsRenderer, MapDirectionsService } from '@angular/google-maps';


@Component({
  selector: 'app-structures',
  templateUrl: './structures.component.html',
  styleUrls: ['./structures.component.scss']
})
export class StructuresComponent implements OnInit {

	public data:any = {page:'', codeType:3, region:'', departement:'', nom:''};
	public loading:boolean = false;
  public searchValues:any = {
    'page': this.data?.page,
    "codeType": 3,
    "region": this.data?.region,
    "departement": this.data?.departement,
    "nom":this.data?.nom,
    "size": 12,
  }

  public currentRegionCoords:any = ''

	public defaultRegions:any = [];

  collection:any = [];
  structures:any = [];
  regions:any = [];
  selectRegion:any = '';
  depts:any = [];
  currentPage = 1;
  currentRegionName:any = ''
  p = 1;
  pageId:any = ''
  itemsPerPage = 12;
  totalItems : any = 0; 
  center: google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 };
  zoom = 7.63;
  telStructure:string = ''
  infoContent: string = '';
  zoomPosition: any;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow?: MapInfoWindow;
  @ViewChild(MapDirectionsRenderer) directionsRenderer!: MapDirectionsRenderer;

  markerPositions: any[] = [];

  
  directions: any | undefined;
  types: any[] = [{'code':'INITIATIVE_GRATUITE_CSU', 'nom':'INITIATIVE GRATUITE CSU'}, 
    {'code':'SRMSD', 'nom':'SERVICE  REGIONAL'},{'code':'PS', 'nom':'POSTE DE SANTE'},{'code':'CENTRE_SANTE', 'nom':'CENTRE DE SANTE'}, 
    {'code':'EPS', 'nom':'Etablissement de Santé Publique'},{'code':'CASE_SANTE', 'nom':'Case de santé'} 
  ];
  markerInfoContent:any = '';
  point: any = {}//current position

  currentTypeStructure:any = this.pageId =='bureaux_cmu' ? {'code':'INITIATIVE_GRATUITE_CSU', 'nom':'INITIATIVE GRATUITE CSU'} : {'code':'PS', 'nom':'POSTE DE SANTE'};
  
  constructor(private annuaireService: AnnuaireService,  private regionService: RegionService, private route: ActivatedRoute, private directionsService: MapDirectionsService) {
    this.regionService.getDefaultRegions().pipe().subscribe((data:any) => {
			this.defaultRegions = data;
    });
  }

  ngOnInit() {
    this.pageId = this.route.snapshot.paramMap.get('id');
    this.searchValues.codeType = this.pageId == 'bureaux_cmu' ? 5 : 3
    this.data.codeType = this.pageId == 'bureaux_cmu' ? 5 : 3
    this.getAllRegions();
    // this.getPharmacies(this.searchValues);
    // this.getPoints();
    this.getCurrentLocation();
    this.getIPAddress();
  }

  gty(pageSlected: any){
    this.searchValues.page = pageSlected;
    this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
        this.loading = false;
        this.structures = res.data
        this.totalItems = res.totalItems
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  getAllRegions(){
    this.annuaireService.getAllRegions().subscribe((res:any) => {
      this.loading = false;
      console.log(res)
      this.regions = res.data
    },
    err => {
      this.loading = false;
      // this.modalService.dismissAll();
    }
  );
  }

  choixRegion (){
    this.loading = true;
    let spl = this.data.region.split('-');
    this.selectRegion = spl[0]
    this.searchValues.departement = ''
    this.data.departement = ''
    this.data.nom = ''
    this.annuaireService.getDepsByRegion(spl[0])
      .subscribe(res => {
        // console.log(res['hydra:member'])
        this.depts = res.data;
        this.loading = false;
        const index = this.defaultRegions.findIndex((o:any) => o.id == spl[0]);
        this.currentRegionCoords = index !== -1 ? this.defaultRegions[index] :  this.currentRegionCoords;
      });
  }

  choixStructure(){
    const index = this.types.findIndex(o => o.code == this.data.codeType);
    this.currentTypeStructure = this.types[index];
    // console.log('typeS', this.currentTypeStructure)
  }

  getPharmacies(filter:any){
    this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
        this.loading = false;
        this.totalItems = res.totalItems
        this.structures = res.data
        this.retrieveMaps()
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  getPoints(region:any){
    var cardValues = {
      "region": region,
      "codeType": this.pageId =='bureaux_cmu' ? 5 : 3,
      "departement": '',
      "nom":'',
      "size": 50,
      "page": 1,
    }
    
    this.markerPositions = []
    const customIcon = {
      url: 'https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730122411386-position_copie.png', // URL de l'image personnalisée
      scaledSize: new google.maps.Size(35, 35), // Taille (ajustez si nécessaire)
      origin: new google.maps.Point(0, 0), // Origine de l'image
      anchor: new google.maps.Point(25, 50) // Position du point d'ancrage (centre bas de l'image)
    };
    this.markerPositions = []
    this.annuaireService.getStructures(cardValues).subscribe((res:any) => {
        this.loading = false;
        for (var i= 0 ; i<res.data.length ; i++){
          if (res.data[i].lat && res.data[i].lng){
            this.markerPositions.push( 
              {
                position: {lat: Number(res.data[i].lat)  , lng: Number(res.data[i].lng)  }, 
                title: res.data[i].name,
                icon: customIcon,
                // label: {
                //   color: 'red',
                //   text: 'Marker label 1' ,
                // },
              }
            ) 
          }
        }
        
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  onSubmit(isValid: any){
		if (isValid){
      this.searchValues.codeType=this. data.codeType;
      this.searchValues.page = this.p
      this.searchValues.size = 12
      this.searchValues.departement = this.data.departement
      this.searchValues.nom = this.data.nom
      if (this.data.departement == 'aucun'){
        this.searchValues.departement = ''
        this.searchValues.region = this.selectRegion;
      }
      if (this.selectRegion) {
        this.searchValues.region = this.selectRegion;
        this.currentRegionName = ''
      }

      this.searchValues.region = this.data.region == '' ? '':this.selectRegion;
      this.currentRegionName = this.data.region == '' ? '' :  this.currentRegionName = ''
      this.loading = true;
     
      // this.annuaireService.getStructures(patient).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any) => {
      this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {

        this.zoom = 7;
          this.loading = false;
          this.structures = res.data
          this.totalItems = res.totalItems
        },
        err => {
          this.loading = false;
          // this.modalService.dismissAll();
        }
      );
      this.retrieveMaps();
		} else{
			
			console.log('submit not valid');
		}
	}

  retrieveMaps(){
    this.searchValues.size = 150;
    const customIcon = {
      url: 'https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730122411386-position_copie.png', // URL de l'image personnalisée
      scaledSize: new google.maps.Size(35, 35), // Taille (ajustez si nécessaire)
      origin: new google.maps.Point(0, 0), // Origine de l'image
      anchor: new google.maps.Point(25, 50) // Position du point d'ancrage (centre bas de l'image)
    };
    this.markerPositions = []
    this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
      this.searchValues.size = 12;
      this.zoom = 7;
        this.loading = false;
        this.zoom = this.currentRegionCoords.code == 'DK' ? 12 : 9;
        this.center = {
          lat: Number(this.currentRegionCoords.lat),
          lng: Number(this.currentRegionCoords.lng),
        };
        for (var i= 0 ; i<res.data.length ; i++){
          if (res.data[i].lat && res.data[i].lng){
            this.markerPositions.push( 
              {
                position: {lat: Number(res.data[i].lat)  , lng: Number(res.data[i].lng)  }, 
                title: res.data[i].name, 
                icon: customIcon 
                // label: {
                //   color: 'red',
                //   text: 'Marker label 1' ,
                // },
              }
            ) 
          }
        }
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }
  getCurrentLocation() {
    this.loading = true;
  
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        this.loading = false;
        this.point = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log('position actuelle', position)
        // this.geocodeLatLng(this.point);
        // google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 }
        this.center = this.point;
        // this.map.panTo(point);
  
        this.markerInfoContent = "I'm here!";
        
      },
      (error) => {
        this.loading = false;
  
        if (error.PERMISSION_DENIED) {
          alert("Couldn't get your location Permission denied");
        } else if (error.POSITION_UNAVAILABLE) {
          alert(
            "Couldn't get your location Position unavailable"
          );
        } else if (error.TIMEOUT) {
          alert("Couldn't get your location Timed out'");
        } else {
          alert(error.message);
        }
      },
      { enableHighAccuracy: true }
    );
  }

  geocodeLatLng(location: any): Promise<any> {
    let geocoder = new google.maps.Geocoder();
  
    return new Promise((resolve, reject) => {
      geocoder.geocode({ 'location': location }, (results:any, status:any) => {
        // const response = new GeocoderResponse(status, results);
        console.log(status, results);
        // resolve(response);
      });
    });
  }

  public getIPAddress() {
    this.annuaireService.getIPAddress().subscribe((res:any) => {
      this.loading = false;
      this.currentRegionName = res.region

      this.searchValues.region = 798;
      const index = this.defaultRegions.findIndex((o:any) => o.code == res.region_code);
      if (index !== -1){
        this.currentRegionCoords = this.defaultRegions[index];
        this.searchValues.region = this.currentRegionCoords.id;
      } 
      
      this.getPharmacies(this.searchValues);
    },
    err => {
      this.loading = false;
        this.currentRegionName = "Dakar"
        const index = this.defaultRegions.findIndex((o:any) => o.code == 'DK');
        if (index !== -1){
          this.currentRegionCoords = this.defaultRegions[index];
          this.searchValues.region = this.currentRegionCoords.id;
        } 
        this.searchValues.region = 798;
        // this.getPoints(this.searchValues.region);
      
        this.getPharmacies(this.searchValues);
    }
  );
  }

  public getInfoVille(ip:any){
      this.annuaireService.getInfoVille(ip).subscribe((res:any) => {
        this.loading = false;
        this.currentRegionName = res.regionName
        this.getPoints(res.regionName);
        this.searchValues.region = res.regionName
        
        this.getPharmacies(this.searchValues);
      },
      err => {
        this.getPharmacies(this.searchValues);
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  openMapInfo(content: string, phone:string,pos:any, marker: MapMarker): void {
    this.infoContent = content;
    this.infoWindow?.open(marker);
    this.telStructure = phone;
    this.zoomPosition = pos
  }
  zoomer(pos:any){
    // const originPos = { lat: 14.7193856, lng: -17.4522368 };
    const originPos = this.point;
    console.log('pointId',this.point)
    this.center = pos;
    // this.zoom = 7;
    const request: google.maps.DirectionsRequest = {
      origin: originPos,
      destination: pos,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    this.directionsService.route(request).subscribe({
      next: (response) => {
        this.directions = response.result;
      },
      error: (error) => {
        console.error('Error calculating directions:', error);
      },
    });
  }

  voirStructure(item:any){
    if (item.latitude && item.longitude) {
      this.zoom = 17
      // google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 }
      this.center = { lat: Number(item.latitude), lng: Number(item.longitude) };
    }
  }
}
