<div class="main d-flex align-items-center w-100 overlay" id="accueil">
    <div class=""></div> 
    <div class="container-lg px-lg-0">
        <div class="row flex-column-reverse px-4 px-md-2 px-lg-0 flex-md-row d-flex align-items-center justify-content-between w-100" style="margin-top: 70px;">
            <div class="col-md-7 d-flex flex-column gap-4 mt-md-4"> 
                <h2 class="title">COUVERTURE MALADIE POUR VOS PROCHES PARTOUT AU SENEGAL</h2>
                <p class="text">
                    Faciliter l'accès et la gestion des services liés à la Couverture Sanitaire Universelle (CSU) au Sénégal. Notre plateforme SunuCSU répond aux besoins des assurés, des mutuelles de santé, et des structures de soins, tout en modernisant le système de gestion de la santé communautaire.
                </p>
              
                <div class="buttons w-100 d-flex"> 
                    <button class="button" (click)="openPopup()" style="margin-left: 0;">ADHERER A UNE MUTUELLE</button>
                    <button class="button" routerLink="/initiative" >FAIRE UN DON</button>
                    <button class="button" routerLink="/sponshor" >PARRAINAGE</button>
                  </div>
                  
                  <app-insurance-status-popup *ngIf="isPopupVisible" (close)="closePopup()"></app-insurance-status-popup>
  
            </div>
            <div class="col-md-5 px-0 ps-2" style="position: relative;"> 
                <div class="container" >    
                    <img src="../../../assets/images/Frame (1).png" alt="hero" >
                </div>
            </div>
            <div id="about"></div>
        </div>
    </div>
</div>
