<div class="container1">
  <div class="separator separator-dotted separator-content border-dark my-15">
    <h1 class="">Quelques un de nos partenaires</h1>
  </div>  
    <div class="horizontal-list">
      <div class="item" style="margin-top: -17px;"><img style="height: 190px !important;" src="../../../../assets/images/partenaires/enabel.png"></div>
      <div class="item"><img style="height: 72px;" src="../../../../assets/images/partenaires/usaid.png"></div>
      <div class="item"><img style="height: 72px;" src="../../../../assets/images/partenaires/jica.png"></div>
      <div class="item" ><img style="height: 125px;padding: 0;margin-top: -27px;" src="../../../../assets/images/partenaires/bm.png"></div>
      <div class="item"><img style="height: 72px;" src="../../../../assets/images/partenaires/AFD_logo.svg"></div>
      <div class="item"><img src="../../../../assets/images/partenaires/luxdev.png"></div>
    </div>
  </div>
  