<app-navbar/>
<div class="container-fluid" id="block_annuaire">
    <!-- <div class="maps">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1978576.593390445!2d-14.364391938734007!3d14.403662404350019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2ssn!4v1728297238656!5m2!1sfr!2ssn" width="100%" height="650" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div> -->
    <div class="row">
        <div class="col-md-12 col-xs-12">
            <div class="maps">
                <google-map [center]="center" [zoom]="zoom" width="100%" height="450px">
                    <map-marker *ngFor="let position of markerPositions"  #mapMarker="mapMarker" (mapClick)="openMapInfo(position.title!,position.phone, position.position, mapMarker)" [clickable]="true" [title]="position.title" [label]="position.label" [icon]="position.icon" [position]="position.position"></map-marker>
                    <map-info-window>
                        
                        <div style="text-align: center;">
                            <img src="https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730122411386-position_copie.png" alt="Description" style="width: 30px; height: auto; float: left;margin-bottom:15px;">
                            <h5>{{ infoContent }}</h5>
                            <p *ngIf="telStructure"><label>Téléphone:</label> {{telStructure}}</p>
                            
                            <button title="Voir itinéraire" (click)="zoomer(zoomPosition)" style="border: none;margin-bottom: 10px;background: transparent;">
                                <span style="font-size: 11px;">Voir l'itinéraire</span>
                                &nbsp;<img height="30" src="https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730204697109-iteneraire-logo.png"/>
                            </button>
                        </div>
                    </map-info-window>
                
                    <map-directions-renderer *ngIf="directions" [directions]="directions" ></map-directions-renderer>
                </google-map>
            </div>
        </div>
    </div>

    <div class="col-md-12 col-xs-12" id="post">
        <div class="post-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-5">
                        <div class="post-content">
                            <h2 class="post-title">{{currentStructure.name}} </h2>
                            <div class="post-infos">
                                <p><strong>Type : </strong>Spécialiste</p>
                                <p *ngIf="currentStructure.recipientHasSpecialities"><strong>Spécialité : </strong>
                                    <span *ngFor="let spec of currentStructure.recipientHasSpecialities;">{{spec.speciality?.name}}</span>
                                </p>
                                <p><strong>Téléphone : </strong>{{currentStructure.home_phone}}</p>
                                <p><strong>Adresse : </strong>{{currentStructure.address}}</p>

                                <a class="editsearch" href="./specialistes">Modifier la recherche</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-7 bg">
                        <div class="post-services">
                            <h2>Liste des services proposés</h2>
                            <div class="post-lists-services">
                                <p *ngFor="let item of currentStructure.recipientHasServices;">{{item.service?.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer/>