import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../modules/auth/services/auth.service';

@Component({
  selector: 'app-insurance-status-popup',
  templateUrl: './insurance-status-popup.component.html',
  styleUrls: ['./insurance-status-popup.component.scss']
})
export class InsuranceStatusPopupComponent {
  isPopupVisible: boolean = true;

  subscribed: boolean = false;
  hasCodeAssur: boolean = false;

  ngOnInit(): void {
    if(this.authService.getJwtToken()){
      this.authService.identity();
    }

    this.authService.getCurrentUser().subscribe(
      (user: any | null) => {
        console.log('haa', user)
        this.subscribed = user && user.beneficiaryId !== null;
        this.hasCodeAssur = user && user.mutual == null;
      },
    );
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
    this.close.emit();
  }

  @Output() close = new EventEmitter<void>();

  constructor(private router: Router, private authService: AuthService) {}

  navigateToMembership(): void {
    this.router.navigate(['/membership']);
    this.close.emit();
  }

  navigateToLinkCode(): void {
    this.router.navigate(['/membership/exaccount']); // Adjust this to your actual route
    // this.close.emit();
  }
}
