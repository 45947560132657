import { Component, OnInit, ViewChild } from '@angular/core';
import { AnnuaireService } from '../../services/annuaire.service';
import { ActivatedRoute } from '@angular/router';
import { MapInfoWindow, MapMarker, MapDirectionsRenderer, MapDirectionsService } from '@angular/google-maps';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsStructureComponent implements OnInit {

	public data:any = {page:'', codeType:3, specialiste:''};
	public loading:boolean = false;
  public currentStructure:any = { }

  public currentRegionCoords:any = ''

  collection:any = [];
  structures:any = [];
  specialistes:any = [];
  selectSpec:any = '';
  depts:any = [];
  currentPage = 1;
  currentSpecName:any = ''
  p = 1;
  pageId:any = ''
  itemsPerPage = 12;
  totalItems : any = 0; 
  center: google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 };
  zoom = 7;
  telStructure:string = ''
  infoContent: string = '';
  zoomPosition: any;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow?: MapInfoWindow;
  @ViewChild(MapDirectionsRenderer) directionsRenderer!: MapDirectionsRenderer;

  markerPositions: any[] = [];

  
  directions: any | undefined;
  types: any[] = [{'code':'INITIATIVE_GRATUITE_CSU', 'nom':'INITIATIVE GRATUITE CSU'}, 
    {'code':'SRMSD', 'nom':'SERVICE  REGIONAL'},{'code':'PS', 'nom':'POSTE DE SANTE'},{'code':'CENTRE_SANTE', 'nom':'CENTRE DE SANTE'}, 
    {'code':'EPS', 'nom':'Etablissement de Santé Publique'},{'code':'CASE_SANTE', 'nom':'Case de santé'} 
  ];
  markerInfoContent:any = '';
  point: any = {}//current position

  currentTypeStructure:any = this.pageId =='bureaux_cmu' ? {'code':'INITIATIVE_GRATUITE_CSU', 'nom':'INITIATIVE GRATUITE CSU'} : {'code':'PS', 'nom':'POSTE DE SANTE'};
  
  constructor(private annuaireService: AnnuaireService, private route: ActivatedRoute, private directionsService: MapDirectionsService) {
  }

  ngOnInit() {
    this.pageId = this.route.snapshot.paramMap.get('id');
    this.getPoints()
    this.getCurrentLocation()
  }

  getAllRegions(){
    this.annuaireService.getAllSpecialites().subscribe((res:any) => {
      this.loading = false;
      console.log(res)
      this.specialistes = res.data
    },
    err => {
      this.loading = false;
      // this.modalService.dismissAll();
    }
  );
  }

  choixRegion (){
    this.loading = true;
    let spl = this.data.specialiste.split('-');
    this.selectSpec = spl[0]
    this.currentSpecName = spl[1]
  }

  choixStructure(){
    const index = this.types.findIndex(o => o.code == this.data.codeType);
    this.currentTypeStructure = this.types[index];
    // console.log('typeS', this.currentTypeStructure)
  }

  getCurrentLocation() {
    this.loading = true;
  
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        this.loading = false;
        this.point = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log('position actuelle', position)
        // this.geocodeLatLng(this.point);
        // google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 }
        this.center = this.point;
        // this.map.panTo(point);
  
        this.markerInfoContent = "I'm here!";
        
      },
      (error) => {
        this.loading = false;
  
        if (error.PERMISSION_DENIED) {
          alert("Couldn't get your location Permission denied");
        } else if (error.POSITION_UNAVAILABLE) {
          alert(
            "Couldn't get your location Position unavailable"
          );
        } else if (error.TIMEOUT) {
          alert("Couldn't get your location Timed out'");
        } else {
          alert(error.message);
        }
      },
      { enableHighAccuracy: true }
    );
  }

  getPoints(){
    
    const customIcon = {
      url: 'https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730122411386-position_copie.png', // URL de l'image personnalisée
      scaledSize: new google.maps.Size(35, 35), // Taille (ajustez si nécessaire)
      origin: new google.maps.Point(0, 0), // Origine de l'image
      anchor: new google.maps.Point(25, 50) // Position du point d'ancrage (centre bas de l'image)
    };
    this.markerPositions = []
    this.annuaireService.getStructureById(this.pageId).subscribe((res:any) => {
        this.loading = false;
        this.currentStructure = res
       console.log(res)
        if (res.lat && res.lng){
          this.markerPositions.push( 
            {
              position: {lat: Number(res.lat)  , lng: Number(res.lng)  }, 
              title: res.name,
              icon: customIcon,
              // label: {
              //   color: 'red',
              //   text: 'Marker label 1' ,
              // },
            }
          ) 
        }
        
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  openMapInfo(content: string, phone:string,pos:any, marker: MapMarker): void {
    this.infoContent = content;
    this.infoWindow?.open(marker);
    this.telStructure = phone;
    this.zoomPosition = pos
  }
  zoomer(pos:any){
    // const originPos = { lat: 14.7193856, lng: -17.4522368 };
    const originPos = this.point;
    console.log('pointId',this.point)
    this.center = pos;
    // this.zoom = 7;
    const request: google.maps.DirectionsRequest = {
      origin: originPos,
      destination: pos,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    this.directionsService.route(request).subscribe({
      next: (response) => {
        this.directions = response.result;
      },
      error: (error) => {
        console.error('Error calculating directions:', error);
      },
    });
  }

  voirStructure(item:any){
    if (item.latitude && item.longitude) {
      this.zoom = 17
      // google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 }
      this.center = { lat: Number(item.latitude), lng: Number(item.longitude) };
    }
  }
}
