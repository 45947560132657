import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RegionService {

	public getDefaultRegions(): Observable<any> {
		let data: any = [
			{
				"id": 798,
				"lat": 14.7110099,
				"lng": -17.5482263,
				"name": "Dakar",
				"code": "DK"
			},
			{
				"id": 803,
				"lat": 14.6542966,
				"lng": -16.2556246,
				"name": "Diourbel",
				"code": "DL"
			},
			{
				"id": 807,
				"lat": 14.3344536,
				"lng": -16.4297321,
				"name": "Fatick",
				"code": "FK"
			},
			{
				"id": 811,
				"lat": 14.1572425,
				"lng": -16.1477632,
				"name": "Kaolack",
				"code": "KL"
			},
			{
				"id": 815,
				"lat": 12.9057135,
				"lng": -15.0249791,
				"name": "Kolda",
				"code": "KD"
			},
			{
				"id": 819,
				"lat": 15.6290229,
				"lng": -16.2212168,
				"name": "Louga",
				"code": "LG"
			},
			{
				"id": 823,
				"lat": 15.6540517,
				"lng": -13.2652618,
				"name": "Matam",
				"code": "MT"
			},
			{
				"id": 827,
				"lat": 15.6540517,
				"lng": -13.2652618,
				"name": "Saint-Louis",
				"code": "SL"
			},
			{
				"id": 831,
				"lat": 13.7708963,
				"lng": -13.6897317,
				"name": "Tambacounda",
				"code": "TC"
			},
			{
				"id": 836,
				"lat": 14.7885611,
				"lng": -16.9684687,
				"name": "Thiès",
				"code": "TH"
			},
			{
				"id": 840,
				"lat": 12.5598999,
				"lng": -16.2947831,
				"name": "Ziguinchor",
				"code": "ZG"
			},
			{
				"id": 844,
				"lat": 12.7045244,
				"lng": -15.5701376,
				"name": "Sédhiou",
				"code": "SD"
			},
			{
				"id": 848,
				"lat": 14.1048086,
				"lng": -15.5655113,
				"name": "Kaffrine",
				"code": "KF"
			},
			{
				"id": 853,
				"lat": 12.5577404,
				"lng": -12.2066485,
				"name": "Kédougou",
				"code": "KG"
			}
		];
		return of(data);
	}
}
