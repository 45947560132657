
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';
import { Membership, MembershipResponse, LinkCode } from '../models/membership.model';

@Injectable({
  providedIn: 'root'
})
export class MembershipServiceService {
  private membershipId: number | null = null;

  constructor(private http: HttpClient) {}

  submitMembership(membershipData: any): Observable<any> {
    const membershipPayload = this.formatMembershipData(membershipData);

    return this.http.post<Membership>(`${environment.apiBenef}${Endpoint.MEMBERSHIP}`, membershipPayload).pipe(
      catchError(error => {
        console.error('Error during membership submission:', error);
        return throwError(error);
      }),
      map(response => {
        console.log('Membership submission response:', response);
        if (response.id) {
          this.setMembershipId(response.id);
        }
        return response;
      })
    );
  }

  submitLinkCode(membershipPayload: any): Observable<any> {
    // return this.http.post<any[]>(`${environment.apiBenef}${Endpoint.LINKCODE}`, membershipPayload).pipe(
    //   catchError(error => {
    //     console.error('Error during link code submission:', error);
    //     return throwError(error);
    //   }),
    //   map(response => {
    //     console.log('Link code submission response:', response);
    //     // if (response.id) {
    //     //   this.setMembershipId(response.id);
    //     // }
    //     return response;
    //   })
    // );

    return this.http.post<any[]>(environment.apiBenef + Endpoint.LINKCODE, membershipPayload);
  }

  getMembership(): Observable<MembershipResponse> {
    return this.http.get<MembershipResponse>(`${environment.apiBenef}${Endpoint.MEMBERSHIP}`).pipe(
      catchError(error => {
        console.error('Error during membership retrieval:', error);
        return throwError(error);
      }),
      map(response => {
        console.log('Membership retrieval response:', response);
        return response;
      })
    );
  }

  getBenefByCode(code:String): Observable<MembershipResponse> {
    return this.http.get<any>(`${environment.apiBenef}${Endpoint.FIND_ASSURE}?code=` + code).pipe(
      catchError(error => {
        console.error('Error during membership retrieval:', error);
        return throwError(error);
      }),
      map(response => {
        console.log('Membership response:', response);
        return response;
      })
    );
  }

  getMembershipId(): number | null {
    return this.membershipId;
  }

  private setMembershipId(id: number): void {
    this.membershipId = id;
  }
  
  private formatMembershipData(data: any): Membership {
    return {
      dateAdhesion: data.dateAdhesion ,
      adhesionType: data.adhesionType ,
      nbrMembre: data.nbrMembre || 1,
      nbrAdherent: data.nbrAdherent || 0,
      prenom: data.prenom || '',  
      nom: data.nom || '',
      adresse: data.adresse || '',
      sexe: data.sexe || 'MASCULIN',
      email: data.email || '',
      telephone: data.telephone || '',
      lieuNaissance: data.lieuNaissance || '',
      dateNaissance: data.dateNaissance || '',
      typePiece: data.typePiece || '',
      numeroPiece: data.numeroPiece || '',
      denomination: data.denomination || '',
      structureId: data.structureId || 0,
      beneficiaryTypeId: data.beneficiaryTypeId || 1,
      photo: data.photo || '',
      photoContentType: data.photoContentType || ''
    };
  }
}

