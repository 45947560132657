import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common'
import { ApiResponse, BeneficiaryModel, Beneficiary } from '~/app/membership/models/beneficiary.model';
import { ListBeneficiairesService } from '~/app/membership/services/list-beneficiaires.service';

@Component({
    selector: 'app-family-list',
  templateUrl: './family-list.component.html',
  styleUrls: ['./family-list.component.scss'],
  providers: [DatePipe]
})
export class FamilyListComponent implements OnInit, OnDestroy {
  beneficiaries: Beneficiary[] = [];
  loading = false;
  page = 1;
  pageSize = 10;
  page_error = false;
  items: BeneficiaryModel[] = [];
  totalItems = 0;
  urlSubscription !: Subscription;

  constructor(private beneficiaryService: ListBeneficiairesService , private datePipe: DatePipe) { }

  getCurrentDateFormatted(): string {
    return this.datePipe.transform(new Date(), 'dd/MM/yyyy') || '-';
  }

  ngOnDestroy(): void {
    this.urlSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    this.loading = true;
    this.beneficiaryService.findAll(this.page, this.pageSize, '', true).subscribe({
      next: (res: ApiResponse<BeneficiaryModel>) => {
        this.items = res.data;
        console.log('Data Items:', this.items);
        
        // Récupérer tous les bénéficiaires de chaque élément de data
        this.beneficiaries = this.items.flatMap(item => item.beneficiaries || []);
        //this.beneficiaries = res.data[0].beneficiaries;
        console.log('Bénéficiaires récupérés:', this.beneficiaries);
        this.totalItems = res.totalItems;
        this.loading = false;
        this.page_error = false;
      },
      error: (error) => {
        this.loading = false;
        this.page_error = true;
      }
    });
  }
  getMutuelleName(mutualId: number): string {
    for (let item of this.items) {
      if (item.mutual && item.mutual.id === mutualId) {
        return item.mutual.basicInfo.libelle?.replace('Mutuelle de Santé Départementale de','SENCSU-')?.replace('Mutuelle de Santé Departementale de ','SENCSU-');
                              
      }
    }
    return '-';
  }
 
}
