import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private qrCode: string | null = null;
  private reference: string | null = null;
  private amount: string | null = null;

  setQrCode(qrCode: string) {
    this.qrCode = qrCode;
  }

  getQrCode(): string | null {
    return this.qrCode;
  }

  setReference(reference: string) {
    this.reference = reference;
  }

  getReference(): string | null {
    return this.reference;
  }

  setAmount(amount: string) {
    this.amount = amount;
  }

  getAmount(): string | null {
    return this.amount;
  }
}
