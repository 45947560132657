<nav class="navbar fixed-top h-100px w-100 navbar-expand-md bg-body-tertiary">
    <div class="container-lg px-2 px-lg-0">
      <a class="navbar-brand" routerLink="/">
        <img src="../../../assets/images/logo_sunucsu.png" alt="logo" class="logo" width="130">
      </a>
      <button class="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center gap-4">
          <li class="nav-item">
            <!-- <a class="nav-link" aria-current="page" href="#accueil" routerLink="/">Accueil</a> -->
            <a class="nav-link" aria-current="page" href="#accueil">Accueil</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="./#about">Comment ça marche</a> 
            
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#block_annuaire">Annuaire</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/contact']" class="nav-link">Contact</a>
          </li>
         
          <li class="nav-item conn" *ngIf="!isLoggedIn">
            <button class="btn btn-connexion" routerLink="/auth/login">Connexion</button>
        </li>
        <li class="nav-item conn" *ngIf="isLoggedIn">
            <button class="nav-link" routerLink="/base">Mon Espace</button>
        </li>
        
          
        </ul>
      </div>
    </div>
</nav>


