import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '~/commons/guard';
//import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
//import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { ContactComponent } from './components/contact/contact.component';
import { StructuresComponent } from './annuaire/components/structures/structures.component';
import { DetailsStructureComponent } from './annuaire/components/details/details.component';
import { DetailsSpecialisteComponent } from './annuaire/components/details-specialiste/details-specialiste.component';
import { SpecialistesComponent } from './annuaire/components/specialistes/specialistes.component';
import { AssureursComponent } from './annuaire/components/assureurs/assureurs.component';
import { PharmaciesComponent } from './annuaire/components/pharmacies/pharmacies.component';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { SettingComponent } from './layouts/setting/setting.component';
import { SponshorLayoutComponent } from './sponsorship/sponshor-layout/sponshor-layout.component';
import { CategoryComponent } from './sponsorship/category/category.component';
import { ChoiceMutualComponent } from './sponsorship/choice-mutual/choice-mutual.component';
import { PaymentDetailComponent } from './sponsorship/payment-detail/payment-detail.component';
import { ScannerDetailsComponent } from './layouts/scanner-details/scanner-details.component';
import { MembershipLayoutComponent } from './membership/membership-layout/membership-layout.component';
import { MembershipExAccountComponent } from './membership/membership-account/membership-account.component';
import { InitiativeLayoutComponent } from './don/initiative-layout/initiative-layout.component';
import { CategorieInitiativeComponent } from './don/categorie-initiative/categorie-initiative.component';
import { AddBenefeciaryComponent } from './membership/add-benefeciary/add-benefeciary.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { PaymentErrorComponent } from './pages/payment-error/payment-error.component';
import { PaymentCesarienneComponent } from './don/payment-cesarienne/payment-cesarienne.component';
import { PaymentInitiativeComponent } from './don/payment-initiative/payment-initiative.component';
import { MutuelleDonationComponent } from './don/mutuelle-donation/mutuelle-donation.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    // canActivate:[AuthGuard],
  
  },
  {
    path: 'initiative',
    component: InitiativeLayoutComponent,
    canActivate:[AuthGuard],
  
  },
  { path: 'payment', component: PaymentCesarienneComponent },
  { path: 'payment-initiative', component: PaymentInitiativeComponent },
  {path: 'initiative/:id', component: CategorieInitiativeComponent },
  {path: 'mutuelle-donation' , component: MutuelleDonationComponent},
  
  {
    path: 'sponshor',
    component: SponshorLayoutComponent,
    canActivate:[AuthGuard],
  
  },
  {
    path: 'membership',
    component: MembershipLayoutComponent,
    canActivate:[AuthGuard],
  
  },
  {
    path: 'membership/exaccount',
    component: MembershipExAccountComponent,
    canActivate:[AuthGuard],
  },
  {
    path: 'add-benef/me',
    component: AddBenefeciaryComponent,
    canActivate:[AuthGuard],
  
  },
  
  { path: 'category/:type', component: CategoryComponent },
  { path: 'choice-mutual', component: ChoiceMutualComponent },
  { path: 'payment-detail', component: PaymentDetailComponent },

  {
    path:'scanner', component: ScannerDetailsComponent,
    //canActivate:[AlreadyAuthicatedGuard]
  },
  {
    path: 'base', 
    component: BaseLayoutComponent,
    canActivate:[AuthGuard],
    children: [
      { path: 'user', loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule) },
      
    ]
  
  },
  
  {
    path: 'contact',
    component: ContactComponent,
    // canActivate:[AuthGuard],
  
  },

  {
    path: 'structures',
    component: StructuresComponent,
    // canActivate:[AuthGuard],
  
  },
  {
    path: 'specialistes',
    component: SpecialistesComponent,
    // canActivate:[AuthGuard],
  
  },
  {
    path: 'assureurs',
    component: AssureursComponent,
    // canActivate:[AuthGuard],
  
  },
  { path: 'structures/details/:id', component: DetailsStructureComponent }
  ,
  { path: 'specialiste/details/:id', component: DetailsSpecialisteComponent }
  ,
  { path: 'structures/:id', component: StructuresComponent }
  ,
  {
    path: 'pharmacies',
    component: PharmaciesComponent,
    // canActivate:[AuthGuard],
  
  },

  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
    
  },
  {
    path: 'payment-error',
    component: PaymentErrorComponent,
    
  
  },

  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  /*{ path: 'error', canActivate:[AuthGuard], loadChildren: () => import('./modules/errors/errors.module').then(m => m.ErrorsModule) },
  { path: '**', redirectTo: 'error/404' },*/

  /*{ path: 'error', canActivate:[AuthGuard], loadChildren: () => import('./modules/errors/errors.module').then(m => m.ErrorsModule) },
  { path: '**', redirectTo: 'error/404' },*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
