<app-navbar></app-navbar>
<div class="main d-flex align-items-center w-100">
    <div class="overlay w-100">
        <div class="mt-10 d-flex justify-content-center">
            <h1 class="text-primary custom-title">LIER MON CODE ASSURÉ CSU</h1>
        </div>
        <div class="mt-5 text-center d-flex justify-content-center">
            <div class="custom">
                <p class="custom-text text-primary">
                    La Couverture Sanitaire Universelle (CSU) au Sénégal est un système de santé visant à garantir 
                    à tous les citoyens l'accès à des soins de qualité, quel que soit leur statut socio-économique, 
                    sans pour autant qu'ils aient à supporter des coûts financiers trop élevés. 
                    Cette approche s'inscrit dans une volonté globale du gouvernement et de la communauté internationale (notamment l'OMS) 
                    de permettre à chaque individu d'accéder à des services de santé sans que cela ne constitue un obstacle financier.
                </p>
            </div>

        </div>
        <div class="step">
            <div class="before-step">
                <div class="stepper stepper-pills last" id="kt_stepper_example_clickable" data-kt-stepper="true">
                    <!--begin::Nav-->
                    <div class="stepper-nav flex-center flex-wrap mb-10">
                        <!--begin::Step 1-->
                        <div class="stepper-item mx-0" data-kt-stepper-element="nav" data-kt-stepper-action="step">
                            <!--begin::Wrapper-->
                            <div class="stepper-image">
                                <img src="../../../assets/images/Vector (1).svg" alt="" srcset="">
                            </div>
                            <div class="stepper-wrapper  flex-column align-items-center">
                                <!--begin::Icon-->
                                <div class="stepper-icon w-70px h-70px rounded-circle mx-0"
                                    [ngClass]="{'active': currentStep === 1, 'completed': currentStep > 1}"
                                    (click)="goToStep(1)"
                                    [ngStyle]="{'background': currentStep === 1 ? '#F9C41C' : 'rgba(58, 132, 76, 0.7)'}">

                                    <span>
                                        <svg width="32" height="31" viewBox="0 0 32 31" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_65_5036)">
                                                <path
                                                    d="M31.6367 7.35688C29.8481 -0.0593917 21.6874 -2.46226 16.9083 2.93678C16.6289 3.2631 16.3494 3.61908 15.9861 4.06405C15.7345 3.73774 15.5389 3.44109 15.3433 3.20377C13.0795 0.59324 10.3686 -0.415372 7.23848 0.207594C2.62712 1.09755 -0.586852 6.34826 0.0838908 11.688C0.419262 14.3875 1.53717 16.6124 3.18607 18.5109C6.959 22.8124 11.0114 26.6985 15.0638 30.5846C15.6507 31.1483 16.3773 31.1186 16.9922 30.555C17.9983 29.6057 18.9765 28.6564 19.9546 27.7071C23.0848 24.6219 26.2708 21.5664 29.1214 18.1253C31.6088 15.0698 32.6428 11.4803 31.6367 7.35688Z"
                                                    [ngStyle]="{'fill': currentStep === 1 ? 'black' : 'white'}" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_65_5036">
                                                    <rect width="32" height="31" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                                <!--end::Icon-->
                                <!--begin::Label-->
                                <div class="stepper-label">
                                    <h3 class="stepper-title">
                                        Code assuré
                                    </h3>

                                </div>
                                <!--end::Label-->
                                <!--begin::Image-->


                                <!--end::Image-->
                            </div>
                            <div class="stepper-image mr-n1">
                                <img src="../../../assets/images/Vector (1).svg" alt="" srcset="">
                            </div>
                            <!--end::Wrapper-->
                            <!--begin::Line-->
                            <div class="stepper-line h-40px"></div>
                            <!--end::Line-->
                        </div>
                        <!--end::Step 1-->
                        <!--begin::Step 2-->

                        <!--end::Step 2-->
                        <!--begin::Step 3-->
                        <div class="stepper-item mx-0 my-4" data-kt-stepper-element="nav" data-kt-stepper-action="step">
                            <!--begin::Wrapper-->
                            <div class="stepper-wrapper d-flex flex-column align-items-center">
                                <!--begin::Icon-->
                                <div class="stepper-icon w-70px h-70px mx-0 rounded-circle "
                                    [ngClass]="{'active': currentStep === 2, 'completed': currentStep > 2}"
                                    (click)="goToStep(2)"
                                    [ngStyle]="{'background': currentStep === 2 ? '#F9C41C' : 'rgba(58, 132, 76, 0.7)'}">
                                    <i class="stepper-check fas fa-check"></i>
                                    <span class="stepper-number">
                                        <svg width="25" height="33" viewBox="0 0 25 33" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_65_5026)">
                                                <path
                                                    d="M12.5 22.7002C11.4244 22.7002 10.3488 22.7289 9.27326 22.7002C8.69187 22.6714 8.3721 22.2974 8.34303 21.7507C8.13954 18.96 8.89536 16.7446 11.7733 15.1335C12.9942 14.443 14.2151 13.7237 15.407 12.9469C16.0756 12.5153 16.5116 11.8536 16.5698 10.9905C16.5988 10.3288 16.3372 9.8109 15.814 9.46565C13.4884 7.88326 10.407 7.88326 8.69187 10.7028C8.54652 10.933 8.45931 11.2207 8.40117 11.5084C8.28489 12.1126 7.96512 12.4578 7.38373 12.4578C5.26164 12.4866 3.16861 12.4578 1.04652 12.4578C0.436055 12.4578 0.116288 12.1413 0.0290785 11.4796C-0.0872006 9.86844 0.0581483 8.34359 0.697683 6.90506C1.86047 4.25815 3.86629 2.38806 6.48257 1.266C10.843 -0.575326 15.2035 -0.460243 19.4186 1.69756C22.064 3.07855 24.0116 5.15005 24.7384 8.08466C25.5523 11.3645 24.6221 14.2128 22.3547 16.7159C21.25 17.9242 19.8256 18.6723 18.4012 19.4203C17.4709 19.9382 16.8605 20.6287 16.657 21.6932C16.5407 22.47 16.2209 22.7289 15.407 22.7289C14.4477 22.7577 13.4593 22.7289 12.5 22.7289V22.7002Z"
                                                    [ngStyle]="{'fill': currentStep === 2 ? 'black' : 'white'}" />
                                                <path
                                                    d="M8.34303 28.8283C8.34303 27.8501 8.34303 26.8719 8.34303 25.8937C8.34303 25.0881 8.75001 24.6853 9.56397 24.6853C11.5407 24.6853 13.4884 24.6853 15.4651 24.6853C16.2791 24.6853 16.7151 25.0881 16.7151 25.8649C16.7442 27.8213 16.7442 29.7777 16.7151 31.7629C16.7151 32.5109 16.25 32.9713 15.4942 32.9713C13.5175 33 11.5407 33 9.53489 32.9713C8.75001 32.9713 8.31396 32.5109 8.31396 31.7341C8.31396 30.7847 8.34303 29.8065 8.34303 28.8283Z"
                                                    [ngStyle]="{'fill': currentStep === 2 ? 'black' : 'white'}" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_65_5026">
                                                    <rect width="25" height="33" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                                <!--end::Icon-->
                                <!--begin::Label-->
                                <div class="stepper-label">
                                    <h3 class="stepper-title">
                                        Vérification
                                    </h3>

                                </div>
                                <!--end::Label-->

                            </div>
                            <!--begin::Image-->
                            <div class="stepper-image mr-n1">
                                <img src="../../../assets/images/Vector (1).svg" alt="" srcset="">
                            </div>
                            <!--end::Image-->
                            <!--end::Wrapper-->
                            <!--begin::Line-->
                            <div class="stepper-line h-40px"></div>
                            <!--end::Line-->
                        </div>
                        <!--end::Step 3-->
                        <!--begin::Step 4-->
                        
                        <!--end::Step 4-->
                        <!--Begin step5-->
                        <div class="stepper-item mx-0 my-4 current" data-kt-stepper-element="nav"
                            data-kt-stepper-action="step">
                            <!--begin::Wrapper-->
                            <div class="stepper-wrapper d-flex flex-column align-items-center">
                                <!--begin::Icon-->
                                <div class="stepper-icon w-70px h-70px mx-0 rounded-circle"
                                    [ngClass]="{'active': currentStep === 3, 'completed': currentStep > 3}"
                                    (click)="goToStep(3)"
                                    [ngStyle]="{'background': currentStep === 3 ? '#F9C41C' : 'rgba(58, 132, 76, 0.7)'}">
                                    <i class="stepper-check fas fa-check"></i>
                                    <span class="stepper-number">
                                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_65_5061)">
                                                <path
                                                    d="M16.5 33C7.38703 33 0 25.613 0 16.5C0 7.38703 7.31799 0 16.5 0C25.613 0 33 7.38703 33 16.5C33 25.682 25.5439 33 16.5 33ZM14.0837 27.546L14.1527 27.477C14.9121 26.1653 15.8096 24.8536 16.7071 23.6799C17.8808 22.0921 19.1925 20.5732 20.5042 19.1234C21.4707 18.0879 22.5063 17.0523 23.6799 16.1548C24.3013 15.6715 24.9916 15.1883 25.751 14.9121C25.8891 14.8431 26.0272 14.8431 26.1653 14.7741C26.1653 14.705 26.1653 14.705 26.0962 14.636C25.613 13.6004 25.4749 12.4958 25.4059 11.3912C25.4059 10.2176 25.5439 8.9749 25.682 7.80126C25.682 7.5251 25.751 7.24895 25.751 6.90377C25.682 6.90377 25.682 6.9728 25.613 6.9728C25.4059 7.11088 25.1987 7.17992 24.9226 7.31799C23.3347 8.28452 21.954 9.45816 20.7113 10.8389C19.8828 11.7364 19.1234 12.6339 18.4331 13.6004C17.5356 14.8431 16.7071 16.0858 15.9477 17.4665C15.3954 18.4331 14.9121 19.3996 14.4289 20.3661C14.1527 20.8494 13.9456 21.4017 13.6695 21.954C12.7029 20.09 11.7364 18.2259 10.7699 16.431C8.76778 17.6046 6.83473 18.8473 4.83264 20.0209C4.90167 20.0209 4.90167 20.0209 4.90167 20.09C5.66109 20.3661 6.4205 20.7803 7.11088 21.2636C8.42259 22.0921 9.59623 23.0586 10.7008 24.1632C11.6674 25.0607 12.5649 26.0272 13.3933 26.9937C13.6695 27.1318 13.8766 27.3389 14.0837 27.546Z"
                                                    [ngStyle]="{'fill': currentStep === 4 ? 'black' : 'white'}" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_65_5061">
                                                    <rect width="33" height="33" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </div>
                                <!--end::Icon-->
                                <!--begin::Label-->
                                <div class="stepper-label">
                                    <h3 class="stepper-title">Validation</h3>
                                </div>
                                <!--end::Label-->
                            </div>
                            <!--begin::Image-->
                            <div class="stepper-image">
                                <img src="../../../assets/images/Vector (1).svg" alt="" srcset="">
                            </div>
                            <!--end::Image-->
                            <!--end::Wrapper-->
                        </div>

                        <!--end::Step 5-->
                    </div>
                    <!--end::Nav-->
                </div>
            </div>

            <!-- Form Fields for Step 1: Mutuelle -->
            <div class=" d-flex " style="min-height: 100%;">
                <div class="form-step w-100" id="step1">
                    <div class="form-step d-flex justify-content-center" *ngIf="currentStep === 1">
                        <div class="box">
                            <img src="../../../assets/images/image 3.png" alt="Votre Image">
                        </div>
                        <div class="w-50 responsive-center">
                            <form (ngSubmit)="goToNextStep2()" class="w-100">
                                <fieldset class="fiel"
                                    style="box-shadow: 0 4px 8px 10px rgba(0, 0, 0, 0.1); min-height: 300px !important;">
                                    <legend class=" me-2 text-center bg-secondary w-150px ">
                                        <svg width="11" height="14" viewBox="0 0 11 14" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_81_5488)">
                                                <path
                                                    d="M5.50035 9.63045C5.02709 9.63045 4.55384 9.64265 4.08058 9.63045C3.82477 9.61824 3.68407 9.45956 3.67128 9.22766C3.58175 8.0437 3.9143 7.10385 5.18058 6.42033C5.71779 6.12739 6.255 5.82225 6.77942 5.49269C7.07361 5.30961 7.26547 5.02888 7.29105 4.6627C7.30384 4.38197 7.18872 4.16227 6.95849 4.0158C5.93523 3.34448 4.57942 3.34448 3.82477 4.54065C3.76082 4.63829 3.72244 4.76035 3.69686 4.88241C3.6457 5.13873 3.505 5.2852 3.24919 5.2852C2.31547 5.2974 1.39454 5.2852 0.460816 5.2852C0.192211 5.2852 0.0515133 5.15093 0.0131412 4.8702C-0.0380216 4.18668 0.0259319 3.53977 0.307327 2.92949C0.818955 1.80656 1.70151 1.01318 2.85268 0.537157C4.77128 -0.244011 6.68988 -0.195188 8.54454 0.720244C9.70849 1.30612 10.5655 2.18493 10.8852 3.42992C11.2434 4.82138 10.8341 6.02975 9.8364 7.09165C9.35035 7.60429 8.72361 7.92164 8.09686 8.23899C7.68756 8.45869 7.41895 8.75163 7.32942 9.20324C7.27826 9.5328 7.13756 9.64265 6.77942 9.64265C6.35733 9.65486 5.92244 9.64265 5.50035 9.64265V9.63045Z"
                                                    fill="black" />
                                                <path
                                                    d="M3.67099 12.2303C3.67099 11.8153 3.67099 11.4003 3.67099 10.9853C3.67099 10.6435 3.85006 10.4727 4.2082 10.4727C5.07797 10.4727 5.93495 10.4727 6.80471 10.4727C7.16285 10.4727 7.35471 10.6435 7.35471 10.9731C7.36751 11.8031 7.36751 12.6331 7.35471 13.4753C7.35471 13.7926 7.15006 13.9879 6.81751 13.9879C5.94774 14.0001 5.07797 14.0001 4.19541 13.9879C3.85006 13.9879 3.6582 13.7926 3.6582 13.4631C3.6582 13.0603 3.67099 12.6453 3.67099 12.2303Z"
                                                    fill="black" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_81_5488">
                                                    <rect width="11" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Étape 1
                                    </legend>
                                    <div>
                                        <div class="main justify-content-center align-items-center">

                                            <div class="container d-flex flex-column w-100">
                                                <div class="w-100">
                                                    <h4 class="text-primary">Veuillez renseigner votre code assuré</h4>
                                                    <input type="text" class="form-control" placeholder="Code assuré" [(ngModel)]="codeAssure" name="codeAssure" id="codeAssure"  required>
                                                </div>

                                                <div *ngIf="!infoAssure" class="w-100" style="color: #000 !important;
                                                background-color: #ffdddd !important;
                                                padding: 15px;
                                                margin-top: 15px;">
                                                <span onclick="this.parentElement.style.display='none'" style="position: absolute;
                                                right: 0;
                                                top: 0;" class="w3-button w3-large w3-display-topright">×</span>
                                                    Bénéficiare introuvable
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </fieldset>


                                <!-- Bouton Suivant -->
                                <div class="d-flex justify-content-end mt-10">

                                    <button [disabled]="!codeAssure" type="submit" class="btn btn2 btn-secondary text-black mb-3">Suivant
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.04102 1L4.78328 4.9685L1.04102 9" stroke="black"
                                                stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </button>


                                </div>

                            </form>
                        </div>

                    </div>
                </div>

            </div>

            <div class="d-flex justify-content-center " style="min-height: auto;">
                <div class="form-step w-100" id="step3">
                    <div class="form-step d-flex justify-content-center" *ngIf="currentStep === 2">
                        <div class="box">
                            <img src="../../../assets/images/image 3.png" alt="Votre Image">
                        </div>
                        <div class="w-50 responsive-center">
                            <form (ngSubmit)="goToNextStep3()">
                                <fieldset class="fiel" style="box-shadow: 0 4px 8px 10px rgba(0, 0, 0, 0.1);">
                                    <legend class=" me-2 text-center bg-secondary w-150px ">
                                        <svg width="11" height="14" viewBox="0 0 11 14" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_81_5488)">
                                                <path
                                                    d="M5.50035 9.63045C5.02709 9.63045 4.55384 9.64265 4.08058 9.63045C3.82477 9.61824 3.68407 9.45956 3.67128 9.22766C3.58175 8.0437 3.9143 7.10385 5.18058 6.42033C5.71779 6.12739 6.255 5.82225 6.77942 5.49269C7.07361 5.30961 7.26547 5.02888 7.29105 4.6627C7.30384 4.38197 7.18872 4.16227 6.95849 4.0158C5.93523 3.34448 4.57942 3.34448 3.82477 4.54065C3.76082 4.63829 3.72244 4.76035 3.69686 4.88241C3.6457 5.13873 3.505 5.2852 3.24919 5.2852C2.31547 5.2974 1.39454 5.2852 0.460816 5.2852C0.192211 5.2852 0.0515133 5.15093 0.0131412 4.8702C-0.0380216 4.18668 0.0259319 3.53977 0.307327 2.92949C0.818955 1.80656 1.70151 1.01318 2.85268 0.537157C4.77128 -0.244011 6.68988 -0.195188 8.54454 0.720244C9.70849 1.30612 10.5655 2.18493 10.8852 3.42992C11.2434 4.82138 10.8341 6.02975 9.8364 7.09165C9.35035 7.60429 8.72361 7.92164 8.09686 8.23899C7.68756 8.45869 7.41895 8.75163 7.32942 9.20324C7.27826 9.5328 7.13756 9.64265 6.77942 9.64265C6.35733 9.65486 5.92244 9.64265 5.50035 9.64265V9.63045Z"
                                                    fill="black" />
                                                <path
                                                    d="M3.67099 12.2303C3.67099 11.8153 3.67099 11.4003 3.67099 10.9853C3.67099 10.6435 3.85006 10.4727 4.2082 10.4727C5.07797 10.4727 5.93495 10.4727 6.80471 10.4727C7.16285 10.4727 7.35471 10.6435 7.35471 10.9731C7.36751 11.8031 7.36751 12.6331 7.35471 13.4753C7.35471 13.7926 7.15006 13.9879 6.81751 13.9879C5.94774 14.0001 5.07797 14.0001 4.19541 13.9879C3.85006 13.9879 3.6582 13.7926 3.6582 13.4631C3.6582 13.0603 3.67099 12.6453 3.67099 12.2303Z"
                                                    fill="black" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_81_5488">
                                                    <rect width="11" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Étape 2
                                    </legend>

                                    <hr class="w-100" style="box-shadow: 0 4px 8px 10px rgba(0, 0, 0, 0.1);">
                                    <div class="" style="margin:20px;" >
                                        <div class="row" >
                                            <div class="col-md-3 col-xm-6 mt-5">
                                                <p><strong> Code assuré: </strong> {{codeAssure}} </p>
                                            </div>
                                            <div class="col-md-3 col-xm-6 mt-5">
                                                <p><strong> Prénom: </strong> {{infoAssure?.prenom}} </p>
                                            </div>
                                            <div class="col-md-3 col-xm-6 mt-5">
                                                <p><strong> Nom: </strong> {{infoAssure?.nom}} </p>
                                            </div>
                                            <div class="col-md-3 col-xm-6 mt-5">
                                                <p><strong>Téléphone: </strong>{{infoAssure?.telephone}} </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3 col-xm-6 mt-5">
                                                <p><strong>Type couverture : </strong> {{infoAssure?.adhesion?.typeBeneficiaire?.libelle}} </p>
                                            </div>
                                            <div class="col-md-3 col-xm-6 mt-5">
                                                <p><strong>État : </strong>{{infoAssure?.etat}}</p>
                                            </div>
                                            <div class="col-md-3 col-xm-6 mt-5">
                                                <p><strong>Sexe : </strong> {{infoAssure?.sexe}}</p>
                                            </div>
                                            <div class="col-md-3 col-xm-6 mt-5">
                                                <p><strong>Adresse : </strong>{{infoAssure?.adresse}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div class="d-flex justify-content-end mt-10">
                                    <button *ngIf="currentStep >1 " type="button" class="btn btn-primary me-5" (click)="goToPreviousStep()">
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.7832 9L1.04094 5.0315L4.7832 1" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        Précédent
                                    </button>
                                    <button type="submit" class="btn btn-secondary text-black">Suivant
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.04102 1L4.78328 4.9685L1.04102 9" stroke="black"
                                                stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!--Form Fields for step 5 : Validate-->
            <div class="d-flex justify-content-center">
                <div class="form-step w-100" id="step5">
                    <div class="form-step d-flex justify-content-center" *ngIf="currentStep === 3">

                        <div class="box">
                            <img src="../../../assets/images/image 3.png" alt="Votre Image">
                        </div>
                        <div class="w-50 responsive-center">
                            <form (ngSubmit)="submitForm()">
                                <fieldset class="fiel" style="box-shadow: 0 4px 8px 10px rgba(0, 0, 0, 0.1);">
                                    <legend class=" me-2 text-center bg-secondary w-150px ">
                                        <svg width="11" height="14" viewBox="0 0 11 14" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_81_5488)">
                                                <path
                                                    d="M5.50035 9.63045C5.02709 9.63045 4.55384 9.64265 4.08058 9.63045C3.82477 9.61824 3.68407 9.45956 3.67128 9.22766C3.58175 8.0437 3.9143 7.10385 5.18058 6.42033C5.71779 6.12739 6.255 5.82225 6.77942 5.49269C7.07361 5.30961 7.26547 5.02888 7.29105 4.6627C7.30384 4.38197 7.18872 4.16227 6.95849 4.0158C5.93523 3.34448 4.57942 3.34448 3.82477 4.54065C3.76082 4.63829 3.72244 4.76035 3.69686 4.88241C3.6457 5.13873 3.505 5.2852 3.24919 5.2852C2.31547 5.2974 1.39454 5.2852 0.460816 5.2852C0.192211 5.2852 0.0515133 5.15093 0.0131412 4.8702C-0.0380216 4.18668 0.0259319 3.53977 0.307327 2.92949C0.818955 1.80656 1.70151 1.01318 2.85268 0.537157C4.77128 -0.244011 6.68988 -0.195188 8.54454 0.720244C9.70849 1.30612 10.5655 2.18493 10.8852 3.42992C11.2434 4.82138 10.8341 6.02975 9.8364 7.09165C9.35035 7.60429 8.72361 7.92164 8.09686 8.23899C7.68756 8.45869 7.41895 8.75163 7.32942 9.20324C7.27826 9.5328 7.13756 9.64265 6.77942 9.64265C6.35733 9.65486 5.92244 9.64265 5.50035 9.64265V9.63045Z"
                                                    fill="black" />
                                                <path
                                                    d="M3.67099 12.2303C3.67099 11.8153 3.67099 11.4003 3.67099 10.9853C3.67099 10.6435 3.85006 10.4727 4.2082 10.4727C5.07797 10.4727 5.93495 10.4727 6.80471 10.4727C7.16285 10.4727 7.35471 10.6435 7.35471 10.9731C7.36751 11.8031 7.36751 12.6331 7.35471 13.4753C7.35471 13.7926 7.15006 13.9879 6.81751 13.9879C5.94774 14.0001 5.07797 14.0001 4.19541 13.9879C3.85006 13.9879 3.6582 13.7926 3.6582 13.4631C3.6582 13.0603 3.67099 12.6453 3.67099 12.2303Z"
                                                    fill="black" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_81_5488">
                                                    <rect width="11" height="14" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>


                                        Étape 3
                                    </legend>
                                    <hr class="w-100" style="box-shadow: 0 4px 8px 10px rgba(0, 0, 0, 0.1);">
                                    <div class="ml-10 mr-10 mb-10 mt-10">
                                        <div class="" style="margin: 2.5rem;text-align: center;">
                                            Voulez-vous vraiment confirmer l'association de votre compte avec le code assuré <strong>{{codeAssure}}</strong>?

                                        </div>
                                        
                                    </div>
                                    
                                </fieldset>

                                <div class="d-flex justify-content-end mt-10">
                                    <button *ngIf="currentStep >2 " type="button" class="btn btn-primary"
                                        (click)="goToPreviousStep()">
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.7832 9L1.04094 5.0315L4.7832 1" stroke="white" stroke-width="2"
                                                stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>Précédent</button>
                                    <button type="submit" class="btn btn-secondary ms-4 text-black ">Valider
                                        <svg width="6" height="10" viewBox="0 0 6 10" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.04102 1L4.78328 4.9685L1.04102 9" stroke="black"
                                                stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </svg>
                                    </button>
                                </div>


                            </form>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>