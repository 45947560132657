import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';
import { DataService } from '../data.service';
import { MembershipTypeEnum, Sponsorship } from '../models/sponsorship.model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SponsorshipService {
  private sponsorshipId: number | null = null;
  private selectedMutuelleId: number | null = null;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private toastr : ToastrService
  ) {}

  submitSponsorship(): Observable<any> {
    const allData = this.dataService.getAllData();
    console.log('allData,', allData)
    const sponsorshipPayload = this.formatSponsorshipData(allData);
    //sponsorshipPayload.mutualId = 1;
    if (this.selectedMutuelleId !== null) {
      sponsorshipPayload.mutualId = this.selectedMutuelleId; 
    }

     
      this.toastr.success("Submitting sponsorship")
    return this.http.post<Sponsorship>(`${environment.apiBenef}${Endpoint.SPONSHOR}`, sponsorshipPayload).pipe(
      catchError(error => {
        
        
        return throwError(error);
      }),
      map(response => {
        
        if (response.id) {
          this.setSponsorshipId(response.id);
        }
        return response;
      })
    );
  }

  getSponsorshipId(): number | null {
    return this.sponsorshipId;
  }

  private setSponsorshipId(id: number): void {
    this.sponsorshipId = id;
  }

  getAllSponsorships(): Observable<Sponsorship[]> {
    return this.http.get<Sponsorship[]>(`${environment.apiBenef}${Endpoint.SPONSHOR}`).pipe(
      catchError(error => {
        
        return throwError(error);
      })
    );
  }

  private formatSponsorshipData(allData: any): Sponsorship {
     

    const sponsorshipData = allData.sponsorship || {};
    const mutualData = allData.mutual || {};
    const selectedButton = this.dataService.getSelectedButton();

    const sponsorshipPayload: Sponsorship = {
      status: false,
      nbBeneficiaries: sponsorshipData.nbBeneficiaries || 0,
      hasContactPerson: sponsorshipData.hasContactPerson || false,
      contactFirstName: sponsorshipData.contactFirstName || '',
      contactLastName: sponsorshipData.contactLastName || '',
      contactPhone: sponsorshipData.contactPhone || '',
      mutualId: mutualData.mutualId ,
      beneficiaryTypeId: allData.beneficiaryTypeId || 1,
      categoryTBCode: sponsorshipData.categoryTBCode,
      beneficiaries: [],
      family: undefined,
      membershipType: selectedButton === 'Parrainage Familial'
        ? MembershipTypeEnum.FAMILLE
        : MembershipTypeEnum.GROUPE
    };

    if (selectedButton === 'Parrainage(s) individuel(s)' || selectedButton === 'Packet de Base' || selectedButton === 'Packet Complémentaire') {
      sponsorshipPayload.beneficiaries = (sponsorshipData.beneficiaries || []).map((beneficiary: any) => ({
        firstName: beneficiary.firstName ,
        lastName: beneficiary.lastName ,
        phone: beneficiary.phone,
        houseHolder: beneficiary.houseHolder || false,
        mutualId: beneficiary.mutualId ,
        beneficiaryTypeId: beneficiary.beneficiaryTypeId || 1,
        membershipType: MembershipTypeEnum.GROUPE
      }));
      delete sponsorshipPayload.family;
    } else if (selectedButton === 'Parrainage Familial') {
      const familyBeneficiaries = [
        {
          firstName: sponsorshipPayload.contactFirstName,
          lastName: sponsorshipPayload.contactLastName,
          phone: sponsorshipPayload.contactPhone,
          houseHolder: true,
          mutualId: mutualData.mutualId,
          beneficiaryTypeId: allData.beneficiaryTypeId || 1,
          membershipType: MembershipTypeEnum.FAMILLE
        },
        ...(allData.family?.beneficiaries || []).map((beneficiary: any) => ({
          firstName: beneficiary.firstName ,
          lastName: beneficiary.lastName ,
          phone: beneficiary.phone ,
          houseHolder: beneficiary.houseHolder || false,
          mutualId: beneficiary.mutualId ,
          beneficiaryTypeId: beneficiary.beneficiaryTypeId || 1,
          membershipType: MembershipTypeEnum.FAMILLE
        }))
      ];

      sponsorshipPayload.family = {
        
        name: allData.family?.name,
        members: sponsorshipData.nbBeneficiaries,
        beneficiaries: familyBeneficiaries
      };

      delete sponsorshipPayload.beneficiaries;
      delete sponsorshipPayload.contactFirstName;
      delete sponsorshipPayload.contactLastName;
      delete sponsorshipPayload.contactPhone;
    }

    
    // sponsorshipPayload.categoryTBCode: "string",
    return sponsorshipPayload;
  }
}
