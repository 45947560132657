import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { httpInterceptorProviders } from 'src/commons/interceptor';
import { NavbarComponent } from './modules/shared/navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BaseLayoutComponent } from './layouts/base-layout/base-layout.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { PresenterComponent } from './components/presenter/presenter.component';
import { AboutComponent } from './components/about/about.component';
import { AnnuairelistComponent } from './components/annuairelist/annuairelist.component';
import { StructuresComponent } from './annuaire/components/structures/structures.component';
import { SpecialistesComponent } from './annuaire/components/specialistes/specialistes.component';
import { AssureursComponent } from './annuaire/components/assureurs/assureurs.component';
import { DetailsStructureComponent } from './annuaire/components/details/details.component';
import { DetailsSpecialisteComponent } from './annuaire/components/details-specialiste/details-specialiste.component';
import { PharmaciesComponent } from './annuaire/components/pharmacies/pharmacies.component';
import { DetailsInscriptionComponent } from './components/details-inscription/details-inscription.component';
import { DownloadPageComponent } from './components/download-page/download-page.component';
import { PartenairesComponent } from './components/partenaires/partenaires.component';
import { FooterComponent } from './modules/shared/footer/footer.component';
import { ContactComponent } from './components/contact/contact.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SharedModule } from './modules/shared/shared.module';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { SettingComponent } from './layouts/setting/setting.component';
import { TopbarComponent } from './layouts/topbar/topbar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FamilyListComponent } from './layouts/family-list/family-list.component';
import { TransactionListComponent } from './layouts/transaction-list/transaction-list.component';
import { SponsorshipsComponent } from './layouts/sponsorships/sponsorships.component';
import { DonationsComponent } from './layouts/donations/donations.component';
//import { AmChartsModule } from '@amcharts/amcharts3-angular';
//import { NgxApexchartsModule } from 'ngx-apexcharts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SponshorLayoutComponent } from './sponsorship/sponshor-layout/sponshor-layout.component';
import { CategoryComponent } from './sponsorship/category/category.component';
import { SponsorshipIndividualComponent } from './sponsorship/sponsorship-individual/sponsorship-individual.component';
import { SponsorshipFamilyComponent } from './sponsorship/sponsorship-family/sponsorship-family.component';
import { SponsorshipStudentBasePacketComponent } from './sponsorship/sponsorship-student-base-packet/sponsorship-student-base-packet.component';
import { SponsorshipStudentComplementPacketComponent } from './sponsorship/sponsorship-student-complement-packet/sponsorship-student-complement-packet.component';
import { SponsorshipNdongoBasePacketComponent } from './sponsorship/sponsorship-ndongo-base-packet/sponsorship-ndongo-base-packet.component';
import { SponsorshipNdongoComplementPacketComponent } from './sponsorship/sponsorship-ndongo-complement-packet/sponsorship-ndongo-complement-packet.component';
import { ChoiceMutualComponent } from './sponsorship/choice-mutual/choice-mutual.component';
import { PaymentDetailComponent } from './sponsorship/payment-detail/payment-detail.component';
import { PaymentDetailSponshorshipComponent } from './layouts/payment-detail-sponshorship/payment-detail-sponshorship.component';
import { ScannerDetailsComponent } from './layouts/scanner-details/scanner-details.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MembershipLayoutComponent } from './membership/membership-layout/membership-layout.component';
import { MembershipExAccountComponent } from './membership/membership-account/membership-account.component';
import { InsuranceStatusPopupComponent } from './components/insurance-status-popup/insurance-status-popup.component';
import { InitiativeLayoutComponent } from './don/initiative-layout/initiative-layout.component';
import { CategorieInitiativeComponent } from './don/categorie-initiative/categorie-initiative.component';
import { AddBenefeciaryComponent } from './membership/add-benefeciary/add-benefeciary.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { PaymentErrorComponent } from './pages/payment-error/payment-error.component';
import { PaymentCesarienneComponent } from './don/payment-cesarienne/payment-cesarienne.component';
import { InitiativeDetailComponent } from './don/initiative-detail/initiative-detail.component';
import { PaymentInitiativeComponent } from './don/payment-initiative/payment-initiative.component';
import { MutuelleDonationComponent } from './don/mutuelle-donation/mutuelle-donation.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { GoogleMapsModule } from '@angular/google-maps';
import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    BaseLayoutComponent,
    LandingPageComponent,
    PresenterComponent,
    AboutComponent,
    AnnuairelistComponent,
    StructuresComponent,
    SpecialistesComponent,
    DetailsStructureComponent,
    AssureursComponent,
    DetailsSpecialisteComponent,
    PharmaciesComponent,
    DetailsInscriptionComponent,
    DownloadPageComponent,
    PartenairesComponent,
    ContactComponent,
    SidebarComponent,
    SettingComponent,
    TopbarComponent,
    FamilyListComponent,
    TransactionListComponent,
    SponsorshipsComponent,
    DonationsComponent,
    SponshorLayoutComponent,
    CategoryComponent,
    SponsorshipIndividualComponent,
    SponsorshipFamilyComponent,
    SponsorshipStudentBasePacketComponent,
    SponsorshipStudentComplementPacketComponent,
    SponsorshipNdongoBasePacketComponent,
    SponsorshipNdongoComplementPacketComponent,
    ChoiceMutualComponent,
    PaymentDetailComponent,
    PaymentDetailSponshorshipComponent,
    ScannerDetailsComponent,
    MembershipLayoutComponent,
    MembershipExAccountComponent,
    InsuranceStatusPopupComponent,
    InitiativeLayoutComponent,
    CategorieInitiativeComponent,
    AddBenefeciaryComponent,
    PaymentSuccessComponent,
    PaymentErrorComponent,
    PaymentCesarienneComponent,
    InitiativeDetailComponent,
    PaymentInitiativeComponent,
    MutuelleDonationComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot() ,
    NgxIntlTelInputModule,
    SharedModule,
    NgbModule,
    NgApexchartsModule,
    NgSelectModule,
    NgxPaginationModule,
    RecaptchaFormsModule, 
    RecaptchaModule,
    GoogleMapsModule
   
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
