import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';

interface Beneficiary {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

@Component({
  selector: 'app-sponsorship-individual',
  templateUrl: './sponsorship-individual.component.html',
  styleUrls: ['./sponsorship-individual.component.scss']
})
export class SponsorshipIndividualComponent {
  knowBeneficiaries: boolean = false;
  beneficiaries: Beneficiary[] = [];
  numberOfBeneficiaries: number = 0;
  newBeneficiary: Beneficiary = { firstName: '', lastName: '', phoneNumber: '' };
  contactPerson: string = 'contact1';  
  amount: number = 3500;  
  contactFirstName: string = '';
  contactLastName: string = '';
  contactPhone: string = '';
  categoryType!: string;

  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.categoryType = this.route.snapshot.paramMap.get('type')!;
  }

  onContactPersonChange(event: Event): void {
    const select = event.target as HTMLSelectElement;
    this.contactPerson = select.value;
  }

  onRadioChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.knowBeneficiaries = input.value === 'oui';
    if (this.knowBeneficiaries) {
      this.numberOfBeneficiaries = this.beneficiaries.length;
    } else {
      this.beneficiaries = [];
      this.numberOfBeneficiaries = 0;
    }
  }

  addBeneficiary(): void {
    if (this.newBeneficiary.firstName && this.newBeneficiary.lastName && this.newBeneficiary.phoneNumber) {
      this.beneficiaries.push({ ...this.newBeneficiary });
      this.newBeneficiary = { firstName: '', lastName: '', phoneNumber: '' };
      this.numberOfBeneficiaries = this.beneficiaries.length;
    }
  }

  removeBeneficiary(index: number): void {
    this.beneficiaries.splice(index, 1);
    this.numberOfBeneficiaries = this.beneficiaries.length;
  }

  proceedToPayment(): void {
    
  }

  onSubmit() {
    const sponsorshipData = {
      nbBeneficiaries: this.numberOfBeneficiaries,
      hasContactPerson: !!this.contactFirstName,
      contactFirstName: this.contactFirstName,
      contactLastName: this.contactLastName,
      contactPhone: this.contactPhone,
      beneficiaries: this.beneficiaries.map(beneficiary => ({
        firstName: beneficiary.firstName,
        lastName: beneficiary.lastName,
        phone: beneficiary.phoneNumber,
        houseHolder: false
      }))
    };
    this.dataService.setSponsorshipData(sponsorshipData);
    console.log(sponsorshipData);
    this.router.navigate(['/choice-mutual']);
  }
}
