<div class="container-fluid" id="block_annuaire">
    <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
            <h2>Liste des annuaires de <span class="sunu">SUNU</span> <span class="csu">CSU</span></h2>
            <div>
                <p class="mt-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi, .</p>
            </div>
        </div>
    </div>


    <div class="row box">
        <div class="col-md-4 box1">
            <a href="./structures">
                <div class="annuaire">
                    <div >
                        <img src="../../../assets/images/medecin.png" alt="" srcset="">
                    </div>
                    <div class="div1">
                        <h1>Médecin spécialistes</h1>
                        <p>Un médecin spécialiste joue un rôle crucial dans le système de santé en fournissant des soins spécialisés pour des conditions ou des maladies spécifiques</p>
                    </div>

                </div>
            </a>
        </div>
        <div class="col-md-4 box1">
            <a href="./pharmacies">
                <div class="annuaire">
                    <div >
                        <img src="../../../assets/images/Phar.png" alt="" srcset="">
                    </div>
                    <div  class="div1">
                        <h1>Pharmacies</h1>
                        <p>Les pharmacies sont une composante clé des soins de santé, fournissant des médicaments, des conseils, et des services qui favorisent la santé et le bien-être des patients.</p>
                    </div>
                    
                </div>
            </a>
        </div>
        <div class="col-md-4 box1">
            <div class="annuaire">
                <div >
                    <img src="../../../assets/images/assureur.png" alt="" srcset="">
                </div>
                <div class="div1">
                    <h1>Assureurs</h1>
                <p>Les assureurs santé assurent une protection financière contre les coûts médicaux élevés, ils encouragent une approche proactive pour maintenir un bon état de santé à travers la prévention et la gestion des risques. </p>
                </div>
                
            </div>
        </div>
    </div>
    <div class="row box2">
        <div class="col-md-4 box1">
            <a href="javascript:void" [routerLink]="['/structures', 'bureaux_cmu']">
                <div class="annuaire">
                    <div >
                        <img src="../../../assets/images/Bure.png" alt="" srcset="">
                    </div>
                    <div class="div1">
                        <h1>Bureau CMU</h1>
                    <p>Les bureaux de la Couverture Maladie Universelle au Sénégal sont des structures de gestion et d'accompagnement qui facilitent l'accès à une protection santé pour tous les citoyens, en particulier les plus démunis.</p>
                    </div>
                    
                </div>
            </a>
        </div>
        <div class="col-md-4  box1">
            <a href="./structures">
                <div class="annuaire">
                    <div >
                        <img src="../../../assets/images/point.png" alt="" srcset="">
                    </div>
                    <div class="div1">
                        <h1>Points de prestations</h1>
                    <p>Les points de prestation médicales sont variés et couvrent une large gamme de besoins en santé. Ils permettent aux populations de recevoir des soins appropriés selon leurs besoins spécifiques.</p>
                    </div>
                    
                </div>
            </a>
        </div>
        <div class="col-md-4 box1">
            <div>

            </div>
        </div>

    </div>
</div>