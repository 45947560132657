
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';

interface Beneficiary {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

@Component({
  selector: 'app-sponsorship-student-base-packet',
  templateUrl: './sponsorship-student-base-packet.component.html',
  styleUrls: ['./sponsorship-student-base-packet.component.scss']
})
export class SponsorshipStudentBasePacketComponent {
  categoryType!: string;
  knowBeneficiaries: boolean = false;
  beneficiaries: Beneficiary[] = [];
  numberOfBeneficiaries: number = 0;
  newBeneficiary: Beneficiary = { firstName: '', lastName: '', phoneNumber: '' };
  contactPerson: string = 'contact1';  
  amount: number = 1000;  
  contactFirstName: string = '';
  contactLastName: string = '';
  contactPhone: string = '';

  constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.categoryType = this.route.snapshot.paramMap.get('type')!;
    // alert(this.categoryType);
  }

  onContactPersonChange(event: Event): void {
    const select = event.target as HTMLSelectElement;
    this.contactPerson = select.value;
  }

  onRadioChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.knowBeneficiaries = input.value === 'oui';
    if (this.knowBeneficiaries) {
      this.numberOfBeneficiaries = this.beneficiaries.length;
    } else {
      this.beneficiaries = [];
      this.numberOfBeneficiaries = 0;
    }
  }

  addBeneficiary(): void {
    if (this.newBeneficiary.firstName && this.newBeneficiary.lastName && this.newBeneficiary.phoneNumber) {
      this.beneficiaries.push({ ...this.newBeneficiary });
      this.newBeneficiary = { firstName: '', lastName: '', phoneNumber: '' };
      this.numberOfBeneficiaries = this.beneficiaries.length;
    }
  }

  removeBeneficiary(index: number): void {
    this.beneficiaries.splice(index, 1);
    this.numberOfBeneficiaries = this.beneficiaries.length;
  }

  proceedToPayment(): void {
    // this.dataService.setBeneficiaries(this.beneficiaries);
    // this.router.navigate(['/choice-mutual']);
  }

  onSubmit() {
    alert( this.categoryType)
    const sponsorshipData = {
      nbBeneficiaries: this.numberOfBeneficiaries,
      hasContactPerson: !!this.contactFirstName,
      contactFirstName: this.contactFirstName,
      contactLastName: this.contactLastName,
      contactPhone: this.contactPhone,
      categoryTBCode: this.categoryType,
      beneficiaries: this.beneficiaries.map(beneficiary => ({
        firstName: beneficiary.firstName,
        lastName: beneficiary.lastName,
        phone: beneficiary.phoneNumber,
        houseHolder: false
      }))
    };
    this.dataService.setSponsorshipData(sponsorshipData);
    console.log(sponsorshipData);
    this.router.navigate(['/choice-mutual']);
  }
}
