import { Component, OnInit, ViewChild } from '@angular/core';
import { AnnuaireService } from '../../services/annuaire.service';
import { ActivatedRoute } from '@angular/router';
import { MapInfoWindow, MapMarker, MapDirectionsRenderer, MapDirectionsService } from '@angular/google-maps';


@Component({
  selector: 'app-specialistes',
  templateUrl: './specialistes.component.html',
  styleUrls: ['./specialistes.component.scss']
})
export class SpecialistesComponent implements OnInit {

	public data:any = {page:'', codeType:3, specialiste:'', nom:''};
	public loading:boolean = false;
  public searchValues:any = {
    'page': this.data?.page,
    "codeType": 2,
    "specialiste": this.data?.specialiste,
    "nom": this.data?.nom,
    "size": 12,
  }

  public currentRegionCoords:any = ''

  collection:any = [];
  structures:any = [];
  specialistes:any = [];
  selectSpec:any = '';
  depts:any = [];
  currentPage = 1;
  currentSpecName:any = ''
  p = 1;
  pageId:any = ''
  itemsPerPage = 12;
  totalItems : any = 0; 
  center: google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 };
  zoom = 7.63;
  telStructure:string = ''
  infoContent: string = '';
  zoomPosition: any;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow?: MapInfoWindow;
  @ViewChild(MapDirectionsRenderer) directionsRenderer!: MapDirectionsRenderer;

  markerPositions: any[] = [];

  
  directions: any | undefined;
  types: any[] = [{'code':'INITIATIVE_GRATUITE_CSU', 'nom':'INITIATIVE GRATUITE CSU'}, 
    {'code':'SRMSD', 'nom':'SERVICE  REGIONAL'},{'code':'PS', 'nom':'POSTE DE SANTE'},{'code':'CENTRE_SANTE', 'nom':'CENTRE DE SANTE'}, 
    {'code':'EPS', 'nom':'Etablissement de Santé Publique'},{'code':'CASE_SANTE', 'nom':'Case de santé'} 
  ];
  markerInfoContent:any = '';
  point: any = {}//current position

  currentTypeStructure:any = this.pageId =='bureaux_cmu' ? {'code':'INITIATIVE_GRATUITE_CSU', 'nom':'INITIATIVE GRATUITE CSU'} : {'code':'PS', 'nom':'POSTE DE SANTE'};
  
  constructor(private annuaireService: AnnuaireService, private route: ActivatedRoute, private directionsService: MapDirectionsService) {
  }

  ngOnInit() {
    this.pageId = this.route.snapshot.paramMap.get('id');
    this.searchValues.codeType =2;
    this.data.codeType = 2
    this.getAllRegions();
    this.getSpecialistes({})
  }

  gty(pageSlected: any){
    this.searchValues.page = pageSlected;
    this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
        this.loading = false;
        this.structures = res.data
        this.totalItems = res.totalItems
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  getAllRegions(){
    this.annuaireService.getAllSpecialites().subscribe((res:any) => {
      this.loading = false;
      console.log(res)
      this.specialistes = res.data
    },
    err => {
      this.loading = false;
      // this.modalService.dismissAll();
    }
  );
  }

  choixRegion (){
    this.loading = true;
    let spl = this.data.specialiste.split('-');
    this.selectSpec = spl[0]
    this.currentSpecName = spl[1]
  }

  choixStructure(){
    const index = this.types.findIndex(o => o.code == this.data.codeType);
    this.currentTypeStructure = this.types[index];
    // console.log('typeS', this.currentTypeStructure)
  }

  getSpecialistes(filter:any){
    this.annuaireService.getSpecialistes(this.searchValues).subscribe((res:any) => {
        this.loading = false;
        this.totalItems = res.totalItems
        this.structures = res.data
        this.retrieveMaps()
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  getPoints(specialiste:any){
    var cardValues = {
      "specialiste": specialiste,
      "codeType": 2,
      "size": 50,
      "page": 1,
    }
    
    this.markerPositions = []
    const customIcon = {
      url: 'https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730122411386-position_copie.png', // URL de l'image personnalisée
      scaledSize: new google.maps.Size(35, 35), // Taille (ajustez si nécessaire)
      origin: new google.maps.Point(0, 0), // Origine de l'image
      anchor: new google.maps.Point(25, 50) // Position du point d'ancrage (centre bas de l'image)
    };
    this.markerPositions = []
    this.annuaireService.getStructures(cardValues).subscribe((res:any) => {
        this.loading = false;
        for (var i= 0 ; i<res.data.length ; i++){
          if (res.data[i].lat && res.data[i].lng){
            this.markerPositions.push( 
              {
                position: {lat: Number(res.data[i].lat)  , lng: Number(res.data[i].lng)  }, 
                title: res.data[i].name,
                icon: customIcon,
                // label: {
                //   color: 'red',
                //   text: 'Marker label 1' ,
                // },
              }
            ) 
          }
        }
        
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  onSubmit(isValid: any){
		if (isValid){
      this.searchValues.codeType=this.data.codeType;
      this.searchValues.page = this.p
      this.searchValues.nom = this.data.nom
      this.searchValues.size = 12
     
      if (this.selectSpec) {
        this.searchValues.specialiste = this.selectSpec;
      }

      this.searchValues.specialiste = this.data.specialiste == '' ? '':this.selectSpec;
      this.loading = true;
     
			// console.log('submit', this.searchValues);
      // this.annuaireService.getStructures(patient).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any) => {
      this.annuaireService.getSpecialistes(this.searchValues).subscribe((res:any) => {

        this.zoom = 7;
          this.loading = false;
          this.structures = res.data
          this.totalItems = res.totalItems
        },
        err => {
          this.loading = false;
          // this.modalService.dismissAll();
        }
      );
      this.retrieveMaps();
		} else{
			
			console.log('submit not valid');
		}
	}

  retrieveMaps(){
    this.searchValues.size = 150;
    const customIcon = {
      url: 'https://cloud-bgdigital-s3.s3-us-east-2.amazonaws.com/csu/png/1730122411386-position_copie.png', // URL de l'image personnalisée
      scaledSize: new google.maps.Size(35, 35), // Taille (ajustez si nécessaire)
      origin: new google.maps.Point(0, 0), // Origine de l'image
      anchor: new google.maps.Point(25, 50) // Position du point d'ancrage (centre bas de l'image)
    };
    this.markerPositions = []
    this.annuaireService.getStructures(this.searchValues).subscribe((res:any) => {
      this.searchValues.size = 12;
      this.zoom = 7;
        this.loading = false;
        this.zoom = this.currentRegionCoords.code == 'DK' ? 12 : 9;
        this.center = {
          lat: Number(this.currentRegionCoords.lat),
          lng: Number(this.currentRegionCoords.lng),
        };
        for (var i= 0 ; i<res.data.length ; i++){
          if (res.data[i].lat && res.data[i].lng){
            this.markerPositions.push( 
              {
                position: {lat: Number(res.data[i].lat)  , lng: Number(res.data[i].lng)  }, 
                title: res.data[i].name, 
                icon: customIcon 
                // label: {
                //   color: 'red',
                //   text: 'Marker label 1' ,
                // },
              }
            ) 
          }
        }
      },
      err => {
        this.loading = false;
        // this.modalService.dismissAll();
      }
    );
  }

  openMapInfo(content: string, phone:string,pos:any, marker: MapMarker): void {
    this.infoContent = content;
    this.infoWindow?.open(marker);
    this.telStructure = phone;
    this.zoomPosition = pos
  }
  zoomer(pos:any){
    // const originPos = { lat: 14.7193856, lng: -17.4522368 };
    const originPos = this.point;
    console.log('pointId',this.point)
    this.center = pos;
    // this.zoom = 7;
    const request: google.maps.DirectionsRequest = {
      origin: originPos,
      destination: pos,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    this.directionsService.route(request).subscribe({
      next: (response) => {
        this.directions = response.result;
      },
      error: (error) => {
        console.error('Error calculating directions:', error);
      },
    });
  }

  voirStructure(item:any){
    if (item.latitude && item.longitude) {
      this.zoom = 17
      // google.maps.LatLngLiteral = { lat: 14.4196684, lng: -15.8593242 }
      this.center = { lat: Number(item.latitude), lng: Number(item.longitude) };
    }
  }
}
