// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.development.ts`.
// The list of file replacements can be found in `angular.json`.
// `ng build` --configuration=development for building for dev

const BASE_API = 'https://csu-gateway.pdg-dev.com/';

export const environment = {
    production: false,

    /**
     * Fill the api url
     */
    apiAuth: BASE_API+'auth/api',

    /**
     * Fill the api url
     */
     apiBenef: BASE_API+'beneficiaire/api',
 
    /**
     * Fill the api url
     */
     apiEntite: BASE_API+'entite/api',
     
    /**
     * Fill the app name
    */
    appName: 'csu-clientDev',

    recaptcha: {
      siteKey: '6LfKHDwqAAAAAJTtZ9CfrYxdk3LGra_tSdRTWIbc',
    },

    csuMerchGateway:"https://services.pdg-dev.com/csu_payment_services",
    csuSecretId: "e0fb14e6-c5f6-1234-948a-ba0bea52257b",
    csuClientId: "acd8r590-c78c-48a9-a55f-27b0353c3050",
    ipInfo: "https://ipapi.co/json/"
  };