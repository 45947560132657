import { Injectable } from '@angular/core';
import { Annuaire } from '../models/annuaire.model';
import { CrudService } from '~/commons/services';
import { environment } from '~/environments/environment';
import { Endpoint } from '~/commons/constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnuaireService {

  constructor(
    private httpClient: HttpClient
) {
}

  getStructures(search: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBenef}${Endpoint.LIST_RECIPIENTS}` +  this.getSearchValues(search))
  }

  getStructureById(id: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBenef}${Endpoint.LIST_RECIPIENTS}/` +  id)
  }

  getSpecialisteById(id: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBenef}${Endpoint.LIST_RECIPIENTS}/` +  id)
  }

  getSpecialistes(search: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBenef}${Endpoint.LIST_SPECIALISTES_ANNUAIRE}` +  this.getSearchSpecValues(search))
  }

  getAllRegions(){
    return this.httpClient.get<any>(`${environment.apiBenef}${Endpoint.LIST_REGIONS}`)
  }
  
  getAllCategories(){
    return this.httpClient.get<any>(`${environment.apiBenef}${Endpoint.LIST_CATEGORIES_ANNUAIRE}` + '?pageable=false')
  }

  getAllSpecialites(){
    return this.httpClient.get<any>(`${environment.apiBenef}${Endpoint.LIST_SPECIALITES_ANNUAIRES}`)
  }

  public getIPAddress() {
    // return this.httpClient.get<any>("https://ipapi.co/8.8.8.8/json");https://api.ipify.org?format=json
    return this.httpClient.get<any>(`${environment.ipInfo}`);
  }

  public getInfoVille(ip:any) {
    return this.httpClient.get("http://ip-api.com/json/" + ip);
  }

  getDepsByRegion(regionId:any){
    return this.httpClient.get<any>(`${environment.apiBenef}${Endpoint.LIST_DEPT_BY_REGIONS}?search=region.id==${regionId}`)
  }

  getSearchValues(values:any){
    console.log('haaa', values)
    let urlpath = '?page=' + values.page + '&pageable=true'+ '&search=type==' + values.codeType ;
    if (values.departement && values.departement !='aucun'){
      urlpath += ';department.id==' + values.departement
    }
    if (values.categorie){
      urlpath += ';category.id==' + values.categorie
    }
    if (values.region){
      urlpath += ';region.id==' + values.region
    }
    if (values.nom){
      urlpath += ';name==*' + values.nom + '*'
    }
    if (values.size){
      urlpath += '&size=' + values.size
    }
    return urlpath;
  }

  getSearchSpecValues(values:any){
    console.log('values', values)
    let urlpath = '?page=' + values.page + '&pageable=true'+ '&search=type==' + values.codeType ;
    if (values.departement && values.departement !='aucun'){
      urlpath += ';department.id==' + values.departement
    }
    if (values.specialiste){
      urlpath += ';recipientHasSpecialities.speciality.id==' + values.specialiste
    }
    if (values.nom){
      urlpath += ';name==*' + values.nom + '*'
    }
    if (values.size){
      urlpath += '&size=' + values.size
    }
    return urlpath;
  }
}
