<footer class="foot">
    <div class="footer">
        <div class="footer-section">
            <h3>POSITION</h3>
            <p>Cité Keur Gorgui, Immeuble El Hadji <br>Serigne Mérina Sylla <br>
                Dakar - Senegal</p>
        </div>
        <div class="footer-section">
            <h3>MENU</h3>
            <p>Inscrire un bénéficiaire <br>
                Faire un don <br>
                Parrainage et Enrôlement</p>
        </div>
        <div class="footer-section">
            <h3>LIENS UTILS</h3>
            <p>Politique confidentialité <br>
                Foire Aux Question <br>
                Condition Générale d‘Utilisation</p>
        </div>
        <div class="footer-section">
            <h3>CONTACT</h3>
            <p><a href="mailto:contact@sunucsu.sn">contact@sunucsu.sn </a><br>

                +221 33 859 15 15</p>
        </div>

    </div>
    <hr>
    <div class="logo">
        <img src="../../../../assets/images/logo_sunucsu.png" alt="" srcset="">
    </div>
    <div class="feed">
        <h2>&copy; SUNUCSU 2024</h2>
        <div class="icones">
            
                <div>
                    <svg width="14" height="25" viewBox="0 0 14 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.84445 19.5824C4.84445 18.0936 4.84445 16.6048 4.84445 15.116C4.84445 14.2504 4.84445 14.2504 3.93776 14.2504C3.13569 14.2504 2.33362 14.2504 1.53155 14.2504C1.11308 14.2504 0.938721 14.0773 0.938721 13.6618C0.938721 12.5539 0.938721 11.4806 0.938721 10.3726C0.938721 9.88792 1.14796 9.74943 1.6013 9.74943C2.50799 9.74943 3.41467 9.74943 4.28649 9.74943C4.73983 9.74943 4.87932 9.57631 4.87932 9.16083C4.87932 7.94902 4.84445 6.73721 4.98394 5.56002C5.29779 2.9979 7.00655 0.747389 10.3543 0.920505C11.3656 0.955129 12.3769 1.02437 13.3882 1.02437C13.8067 1.02437 14.0159 1.19749 13.9811 1.61297C13.9811 2.47855 13.9811 3.34413 13.9811 4.24433C13.9811 4.69444 13.7718 4.83293 13.3534 4.83293C12.7605 4.83293 12.2026 4.79831 11.6097 4.83293C10.3892 4.90218 9.69174 5.62926 9.69174 6.84108C9.69174 7.56816 9.69174 8.29525 9.69174 9.02234C9.69174 9.7148 9.72661 9.7148 10.4241 9.74943C11.2959 9.74943 12.1677 9.74943 13.0744 9.74943C13.737 9.74943 13.8067 9.81867 13.7021 10.4419C13.5277 11.4806 13.3534 12.4847 13.2139 13.5234C13.1441 14.0773 12.9 14.2504 12.3769 14.2158C11.7143 14.1812 11.0169 14.2504 10.3543 14.1812C9.83123 14.1466 9.69174 14.3543 9.69174 14.839C9.69174 17.8513 9.69174 20.8635 9.69174 23.8757C9.69174 24.8452 9.69174 24.8105 8.75018 24.8105C7.66913 24.7759 6.62295 24.8105 5.5419 24.8105C4.84445 24.8105 4.84445 24.8105 4.84445 24.0834C4.84445 22.6293 4.84445 21.1058 4.84445 19.5824Z" fill="white"/>
                        </svg>
                        
                </div>
            
           
            <div>
                <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.0685 11.0347C22.0685 12.6274 22.0685 14.1854 22.0685 15.7781C22.0685 19.275 19.4879 21.8718 15.9658 21.9064C12.7226 21.9064 9.47947 21.941 6.2712 21.9064C2.81881 21.8718 0.203369 19.275 0.203369 15.8473C0.203369 12.6274 0.203369 9.4074 0.203369 6.22206C0.203369 3.0021 2.60957 0.370737 5.81785 0.266867C9.4446 0.162997 13.1062 0.162997 16.7678 0.266867C19.6971 0.370737 22.0685 3.03673 22.1033 6.08357C22.1033 7.74548 22.0685 9.4074 22.0685 11.0347ZM20.011 11.0693C20.011 9.51127 19.9761 7.95322 20.011 6.39518C20.0807 4.17929 18.372 2.24039 15.9309 2.20577C12.6877 2.17114 9.40973 2.17114 6.16658 2.20577C3.9696 2.24039 2.22598 3.93693 2.22598 6.04894C2.19111 9.33815 2.19111 12.6274 2.22598 15.9166C2.26085 18.0978 3.93474 19.829 6.06196 19.8636C9.37486 19.8982 12.7226 19.8982 16.0355 19.8636C18.3371 19.829 19.9761 18.0978 19.9761 15.8127C20.0459 14.22 20.011 12.662 20.011 11.0693Z" fill="white"/>
                    </svg>
                    
                    
            </div>
            <div>
                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.646 1.20956C21.1926 1.8674 20.7393 2.55986 20.2162 3.32157C20.8091 3.14846 21.367 3.00996 21.9947 2.83685C21.925 3.00996 21.925 3.14846 21.8552 3.25233C21.4368 3.73705 21.0532 4.2564 20.5649 4.63726C20.1465 4.94886 20.007 5.26047 20.007 5.7452C20.0419 8.34194 19.3444 10.6963 17.81 12.8083C15.7874 15.5782 13.0673 17.1709 9.71957 17.7248C6.7554 18.2442 3.96559 17.7248 1.2804 16.3399C1.21066 16.3053 1.10605 16.236 1.0363 16.2014C1.00143 16.1668 1.00143 16.1322 0.966553 16.0283C3.02403 15.9244 5.04664 15.7859 6.89489 14.3318C4.94203 13.9855 3.75636 12.9468 3.02403 11.181C3.61687 11.181 4.10508 11.181 4.5933 11.181C2.74505 10.3155 1.80349 8.93053 1.62913 6.88776C2.25684 7.06088 2.8148 7.234 3.37276 7.40711C3.40763 7.37249 3.40764 7.30324 3.44251 7.26862C2.39633 6.33379 1.62913 5.26047 1.62913 3.77168C1.62913 3.00996 1.80349 2.28288 2.29171 1.52117C2.46607 1.69428 2.60557 1.83277 2.74506 1.97127C4.52356 3.84092 6.61591 5.26047 9.19648 5.81445C9.71957 5.91832 10.2427 5.95294 10.7657 6.02218C11.0796 6.05681 11.1842 5.91831 11.1842 5.60671C11.0796 3.66781 11.8468 2.17901 13.5904 1.31343C15.3689 0.447846 17.0777 0.655586 18.647 1.90202C18.8213 2.04051 19.2049 2.07514 19.449 2.00589C20.1465 1.76353 20.809 1.45192 21.4716 1.14031C21.5414 1.14031 21.6111 1.17493 21.646 1.20956Z" fill="white"/>
                    </svg>
                    
                    
            </div>
            <div>
                <img src="../../../../assets/images/linkdin.png" alt="" srcset="">      
            </div>
        </div>
    </div>
</footer>