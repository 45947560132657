<div class="popup-overlay" >
    
    <div class="popup-content">
      
  <button class="fermer" (click)="closePopup()">X</button>
      <h2>Statut de ma couverture</h2>
      <hr>
      <button *ngIf="!subscribed" class="btn btn-primary" (click)="navigateToMembership()"> Je ne suis pas encore assuré<br> ADHÉRER À LA CSU</button>
      <button *ngIf="!hasCodeAssur" class="btn btn-secondary" (click)="navigateToLinkCode()">Je suis déjà assuré <br> LIER MON CODE D'ASSURE</button>
      <button class="btn btn-danger" (click)="navigateToLinkCode()">Ma couverture maladie a expiré <br> RENOUVELER MON ASSURANCE</button>
    </div>
  </div>
  